import styles from "./SuccessionDocLoader.module.scss";

type ComponentProps = {
  num: number;
  heading: string;
};

function SuccessionDocLoader({ num, heading }: ComponentProps) {
  let arr: number[] = new Array(num).fill(0);

  return (
    <div className={styles.succession_doc_loading_container}>
      <div className={styles.heading}>{heading}</div>
      <div className={`${styles.sub_heading} animate`}></div>
      <div className={`${styles.title} animate`}></div>
      {arr.map((_, index) => (
        <div className={`${styles.details_loader} animate`} key={index}></div>
      ))}
    </div>
  );
}

export default SuccessionDocLoader;
