import CardStyles from "../PersonalInfoCard/PersonalInfoCard.module.scss";
import styles from "./ContactInfoLoader.module.scss";

type ComponentProps = {
  cardNumber: number;
};

export default function ContactInfoLoader({ cardNumber }: ComponentProps) {
  let arr: number[] = new Array(cardNumber).fill(0);
  return (
    <>
      {arr.map((_, index) => (
        <div className={CardStyles.card_holder} key={index}>
          <div className={CardStyles.card}>
            <div className={`${styles.card_profile} animate`}></div>
            <div className={`${styles.card_details} animate`}></div>
          </div>
        </div>
      ))}
    </>
  );
}
