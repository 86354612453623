import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LanguageState {
  currentLanguage: "en" | "th";
}
const initialState: LanguageState = {
  currentLanguage: localStorage.getItem("i18nextLng") === "th" ? "th" : "en", // Persist language preference
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    toggleLanguage(state, action) {
      state.currentLanguage = action.payload;
    },
  },
});

export const { toggleLanguage } = languageSlice.actions;
export default languageSlice.reducer;
