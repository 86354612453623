const config = {
  nodeEnv: process.env.NODE_ENV,
  port: process.env.REACT_APP_PORT,
  clientId: process.env.REACT_APP_CLIENT_ID,
  apiIssuer: process.env.REACT_APP_ISSUER,
  appUrl: process.env.REACT_APP_URL,
  appCode: process.env.REACT_APP_CODE,
  isAPFeatureEnabled: process.env.REACT_APP_AP_FEATURE_FLAG === "true",
};

export default config;
