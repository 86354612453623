import { format } from 'date-fns';
import { localeDateMappings } from '../constants/dataConstants';

export function formatDate(date: any, country: string, formatType: 'short' | 'long' | 'dateTime' = 'short'): string {
	const {dateFormat, longDateFormat, dateTimeFormat } = localeDateMappings[country] || {dateFormat: 'MM/dd/yyyy', longDateFormat: 'MMMM dd, yyyy', dateTimeFormat: 'MM/dd/yy h:mma' };
	let formatString: string;
	const newDate = new Date(date);

	switch (formatType) {
	  case 'long':
		formatString = longDateFormat;
		break;
	  case 'dateTime':
		formatString = dateTimeFormat;
		break;
	  case 'short':
	  default:
		formatString = dateFormat;
	}
	let formattedDate = format(newDate, formatString);

	if (formatType === 'dateTime') {
		formattedDate = formattedDate.replace('AM', 'am').replace('PM', 'pm');
	  }
	  return formattedDate;

}
