import React from "react";
import styles from "./Button.module.scss";
import Spinner from "../Spinner/Spinner";
import Icon from "../Icon/Icon";

interface ButtonProps {
  id?: string;
  onClick: () => void;
  label: string;
  style?: "filled" | "unfilled";
  isDisabled?: boolean;
  isLoading?: boolean;
  customStyle?: React.CSSProperties;
  isIcon?: boolean;
  iconName?: string | undefined;
  iconSize?: number;
  themeColor?: string | undefined;
  disabledThemeColor?: string | undefined;
}

const Button: React.FC<ButtonProps> = ({
  id,
  onClick,
  label,
  style = "unfilled",
  isDisabled = false,
  isLoading = false,
  customStyle = {},
  isIcon = false,
  iconName,
  iconSize,
  themeColor,
  disabledThemeColor
}) => {
  const buttonClass = style;
  return (
    <button
      id={id}
      className={`button ${isDisabled ? `disabled ${styles.disabled}` : ""} ${styles.button} ${buttonClass}`}
      style={customStyle}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div className={styles.btn_inner}>
        {isIcon && 
          <span className={styles.btn_icon}>
            <Icon
              name={iconName}
              size={iconSize}
              className={styles.className}
              color={!isDisabled ? themeColor : disabledThemeColor}
            />
          </span>
        }
        {isLoading ? (
          <span className={styles.spinner}>
            <Spinner loadingSize={`medium`} />
          </span>
        ) : (
          <span className={styles.label}>{label}</span>
        )}
      </div>
    </button>
  );
};

export default Button;
