import React from "react";
import isEmpty from "lodash/isEmpty";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import "./HTMLToText.css";
import styles from "./HTMLToText.module.scss";

interface HTMLtoTextProps {
  htmlContent: string;
  alternateMessage?: string;
}

const HTMLtoText: React.FC<HTMLtoTextProps> = ({
  htmlContent,
  alternateMessage = "",
}) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);
  const stringData = sanitizedHtml?.replace(/<[^>]+>/g, "");
  const reactElement: any = parse(sanitizedHtml);

  return (
    <div className={styles.content_wrapper}>
      {isEmpty(stringData) ? alternateMessage : reactElement}
    </div>
  );
};

export default HTMLtoText;
