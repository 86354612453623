import { createSlice } from "@reduxjs/toolkit";

interface TagState {
  currentTag: string;
}

const initialState: TagState = {
  currentTag: "",
};

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    updateTag: (state, action) => {
      state.currentTag = action.payload;
    },
  },
});

export const { updateTag } = tagSlice.actions;
export default tagSlice.reducer;
