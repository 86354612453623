import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { sortAccountTableData } from "../../../utils/common";
import {
  SortOrder,
  IColumnState,
  IColumnStates
} from "../../../types/types";

import MyAccountTable from "./MyAccountTable/MyAccountTable";
import { aboColumnMapping, PAGE_SIZE } from '../../../constants/dataConstants';
import { useGetAccountListMutation } from "../../../redux/services/myAccountAPI";

import { useGetConfigKpiQuery } from "../../../redux/services/kpiSettingsByTypeAPI";

import { setCustomViewConfigKPIList } from "../../../redux/slices/customisedViewKpiList";
import { isEmpty } from "lodash";


interface IAccountTableRow {
  affiliateCode: string,
  aboNumber: string,
  name: string,
  localName: null,
  marketName: string,
  currentAward: {
    awardCode: string,
    awardName: string
  },
  trackingAward: {
    awardCode: string,
    awardName: string,
    awardTagCode: number,
    awardTagName: string
  },
  groupGrowthIncentive: {
    Baseline: number,
    Tracking: number,
    Actual: number,
  },
  frontlineGrowthIncentive: {
    Baseline: number,
    Tracking: number,
    Actual: number,
  },
  monthlyRevenue: {
    revenue: number,
    revenuePerformancePercentage: string | number,
    localRevenue: number,
    localRevenueCurrency: string,
    localRevenuePerformancePercentage: string | number
  },
  monthlyContributor: {
    contributor: number,
    contributorPerformancePercentage: string | number
  },
  annualRevenue: {
    revenue: number,
    revenuePerformancePercentage: string | number,
    localRevenue: number,
    localRevenueCurrency: string,
    localRevenuePerformancePercentage: string | number
  },
  annualContributor: {
    contributor: number,
    contributorPerformancePercentage: string | number
  },
  currentMonthGroupPV: {
    pv: string | number,
    pvPerformancePercentage: string
  },
  lastMonthGroupPV: {
    pv: string | number,
    pvPerformancePercentage: string
  },
  currentMonthRubyPV: {
    pv: string | number,
    pvPerformancePercentage: string
  },
  lastMonthRubyPV: {
    pv: string | number,
    pvPerformancePercentage: string
  },
  currentMonthPersonalPV: {
    pv: string | number,
    pvPerformancePercentage: string
  }
}

type ComponentProps = {
  data: any;
  viewDetails: any;
}


const AccountTableContainer = ({ data, viewDetails }: ComponentProps) => {
  const { t } = useTranslation();
  const viewId = useAppSelector((state: any) => state.viewIdDetails.viewId.defaultViewId);
  const dispatch = useDispatch();

  const defaultSortState = {
    "name": "nosort" as SortOrder,
    "currentAward.awardName": "nosort" as SortOrder,
    "groupGrowthIncentive.baseline": "nosort" as SortOrder,
    "frontlineGrowthIncentive.baseline": "nosort" as SortOrder,
    "trackingAward.awardName": "nosort" as SortOrder,
    "monthlyRevenue.revenue": "nosort" as SortOrder,
    "annualRevenue.revenue": "nosort" as SortOrder,
    "monthlyContributor.contributor": "nosort" as SortOrder,
    "annualContributor.contributor": "nosort" as SortOrder,
    "lastMonthGroupPV.pv": "nosort" as SortOrder,
    "currentMonthGroupPV.pv": "nosort" as SortOrder,
    "lastMonthRubyPV.pv": "nosort" as SortOrder,
    "currentMonthRubyPV.pv": "nosort" as SortOrder,
    "currentMonthPersonalPV.pv": "nosort" as SortOrder,
    // "qualifiedLeg.ql": "nosort" as SortOrder,
    // "qualifiedMonth.qm": "nosort" as SortOrder,
    "totalSPQualifiedMonthsLos.qualifiedMonth": "nosort" as SortOrder,
    "trackingTwoTimeAward.awardCode": "nosort" as SortOrder,
  };
  const [tableDataList, setTableDataList] = useState<any[] | []>([]);// TODO: IAccountTableRow >> add all type of expected object[with all expected column of keys values] for the table
  const [columnState, setColumnState] = useState<IColumnStates>(defaultSortState);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  useEffect(() => {
    getSortedData(data).then((sortsData: any) => setTableDataList(sortsData));
  }, [data, columnState]);

  // Get All columns list
  const { data: configKpiResponse } = useGetConfigKpiQuery({
    type: "my-abo",
  });

  useEffect(() => {
    if (configKpiResponse && !isEmpty(configKpiResponse?.body)) {
      dispatch(setCustomViewConfigKPIList(configKpiResponse?.body));
    }
  }, [configKpiResponse]);

  const getSortedData = (data: any) => {
    let sortedData = data?.body?.abos || [];

    for (const [column, order] of Object.entries(columnState)) {
      if (order !== 'nosort') {
        sortedData = sortAccountTableData(sortedData, column, order);
        break; // Only sort by one column at a time
      }
    }

    return new Promise((resolve) => resolve(sortedData));
  };

  // Fetch more data from the API when the page changes
  //Account list API
  const [moreAccountList, { isLoading: accountListLoading,
    isError: accountListError,
    data: accountListData,

  }] = useGetAccountListMutation();

  const getMoreTableDataOnScroll = (pageNumber: number) => {
    const maxPageReached = data && data.body.pagination.totalRecord ? (pageNumber <= Math.ceil(data.body.pagination.totalRecord / PAGE_SIZE)) : false;
    if (!maxPageReached || accountListLoading) {
      return;
    };

    moreAccountList({
      payload: {
        viewId: viewId,
        viewSetting: viewDetails
      },
      pageNumber: pageNumber
    }).then((response: any) => {
      if (response) {
        getSortedData(response?.data).then((sortedData: any) => setTableDataList((prevData) => [...prevData, ...sortedData]));
      }
    });
  }

  // Add new table if you want to display
  const tableColumnData = [t("Account_table_headers.IBO_Info"), t("Account_table_headers.Tracking_Award"), t("Account_table_headers.Monthly_Revenue"), t("Account_table_headers.Monthly_Contributors"), t("Account_table_headers.Annual_Revenue YTD"), t("Account_table_headers.Annual_Contributors")];
  const allTableColumnData = [t("Account_table_headers.IBO_Info"), t('Account_table_headers.PGI_PQs'), t('Account_table_headers.FGI_PQs'), t("Account_table_headers.Tracking_Award"), t("Account_table_headers.Monthly_Revenue"), t("Account_table_headers.Monthly_Contributors"), t("Account_table_headers.Annual_Revenue YTD"), t("Account_table_headers.Annual_Contributors"), t("Account_table_headers.Last_Month_GPV"), t("Account_table_headers.This_Month_GPV"), t("Account_table_headers.Last_Month_RPV"), t("Account_table_headers.This_Month_RPV"), t("Account_table_headers.This_Month_PPV"), t("Account_table_headers.Total_Qs"), t("Account_table_headers.ttci_ttca")]

  // Maintain order for the respective column of tableColumnData
  // add all expected table key path for the respective column to be sorted
  const tableColumnNameMapper = ["name", "trackingAward.awardName", "monthlyRevenue.revenue", "monthlyContributor.contributor", "annualRevenue.revenue", "annualContributor.contributor"];
  const allTableColumnNameMapper = ["name", "groupGrowthIncentive.baseline", "frontlineGrowthIncentive.baseline", "trackingAward.awardName", "monthlyRevenue.revenue", "monthlyContributor.contributor", "annualRevenue.revenue", "annualContributor.contributor", "lastMonthGroupPV.pv", "currentMonthGroupPV.pv", "lastMonthRubyPV.pv", "currentMonthRubyPV.pv", "currentMonthPersonalPV.pv", "totalSPQualifiedMonthsLos.qualifiedMonth", "trackingTwoTimeAward.awardCode"];

  const kpis = viewDetails?.kpis;

  const tableColumnList = kpis?.map((kpi: any) => {
    return t(`Account_table_headers.${aboColumnMapping[kpi.code]?.columnName}`);
  });

  const columnNameMapperList = kpis?.map((kpi: any) => {
    return aboColumnMapping[kpi.code]?.valueMapping;
  });

  const filteredTableColumnData = viewId == 1 ? tableColumnData : [t("Account_table_headers.IBO_Info"), ...allTableColumnData.filter((col) =>
    tableColumnList?.includes(col))];

  const filteredColumnNamesMapper = viewId == 1 ? tableColumnNameMapper : ["name", ...allTableColumnNameMapper.filter((col) =>
    columnNameMapperList?.includes(col))];

  return <MyAccountTable tableData={tableDataList} getMoreTableDataOnScroll={getMoreTableDataOnScroll} isMoreAccountListLoading={accountListLoading} isLoading={false} columnState={columnState} setColumnState={setColumnState} tableColumnData={filteredTableColumnData} tableColumnNameMapper={filteredColumnNamesMapper} isSortable={false} />
};

export default AccountTableContainer;
