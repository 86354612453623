import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import isEmpty from "lodash/isEmpty";
import styles from "./Goals.module.scss";
import { useAppSelector } from "../../../../redux/hooks";
import YearDropdown from "../../../common/YearDropdown/YearDropdown";
import { RootState } from "../../../../redux/store";
import Button from "../../../common/Button/Button";
import {
  useGetGoalsDataQuery,
  useUpdateGoalsDataMutation,
} from "../../../../redux/services/goalsAPI";
import FormatterBar from "../../../common/FormatterBar/FormatterBar";
import HTMLtoText from "../../../common/HTMLToText/HTMLToText";
import ABOBackgroundLoader from "../../../common/Loaders/ABOBackgroundLoader/ABOBackgroundLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";

const Goals = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const currentPyYear = currentYear.toString().slice(2);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const selectedYear =
    useAppSelector((state: RootState) => state.selectedYear.selectedYear) ||
    currentPyYear;
  const [isEditable, setIsEditable] = useState(false);
  const [isEditEnable, setIsEditEnable] = useState(true);
  const [goalsData, setGoalsData] = useState("");
  const [updatedGoalsData, setUpdatedGoalsData] = useState("");
  const [isAPIError, setIsAPIError] = useState(false);

  const year = currentYear - Number(currentPyYear);
  const yearTitle = year + Number(selectedYear);

  const {
    data: goalsResponseData,
    isFetching: isLoadingGetGoals,
    isError,
  } = useGetGoalsDataQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      year: selectedYear.toString(),
    },
    { refetchOnMountOrArgChange: true, skip: isAPIError }
  );

  const [
    updateGoalsData,
    { isLoading: isLoadingUpdateGoals, isError: isErrorInSave },
  ] = useUpdateGoalsDataMutation();

  const handleAPIError = () => {
    setIsAPIError(true);
    setGoalsData("");
    setUpdatedGoalsData("");
  };

  useEffect(() => {
    if (isError || isErrorInSave) handleAPIError();
  }, [isError, isErrorInSave]);

  useEffect(() => {
    if (!isEmpty(goalsResponseData)) {
      if (isValidResponse(goalsResponseData?.statusCode)) {
        setGoalsData(goalsResponseData?.body?.note);
        setUpdatedGoalsData(goalsResponseData?.body?.note);
      } else {
        handleAPIError();
      }
    }
  }, [goalsResponseData, isLoadingGetGoals]);

  const handleClickEdit = () => {
    setIsEditable(true);
  };

  const handleClickCancel = () => {
    setIsEditable(false);
    setUpdatedGoalsData(goalsData);
  };

  const handleClickSave = async () => {
    const body = {
      performanceYear: selectedYear.toString(),
      note: updatedGoalsData,
    };

    const response: any = await updateGoalsData({
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      payload: body,
    });

    if (isValidResponse(response?.data?.statusCode)) {
      setIsEditable(false);
      setGoalsData(updatedGoalsData);
    } else {
      handleAPIError();
    }
  };

  useEffect(() => {
    setIsEditable(false);
    if (selectedYear.toString() < currentYear.toString()) {
      setIsEditEnable(false);
    } else {
      setIsEditEnable(true);
    }
  }, [selectedYear, currentYear]);

  var div = document.createElement("div");
  div.innerHTML = goalsData;

  return (
    <div
      className={`goals_wrapper ${styles.goals_wrapper} ${isAPIError ? styles.full_width : ""}`}
    >
      {isLoadingGetGoals || isLoadingUpdateGoals ? (
        <ABOBackgroundLoader num={1} heading={t("goals")} />
      ) : isAPIError ? (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="goals_bg_heading_testID"
            >
              {t("goals")}
            </span>
          </div>
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        </>
      ) : (
        <>
          <div className={styles.heading_area}>
            <span
              className={styles.heading}
              data-testid="goals_bg_heading_testID"
            >
              {t("goals")}
            </span>
            <span className={styles.right_section}>
              {!isEditable && (
                <span
                  className={`${isEditEnable ? styles.clickable : styles.non_clickable} ${styles.edit_section}`}
                  onClick={handleClickEdit}
                  data-testid="edit_testID"
                >
                  <span
                    className={
                      currentTheme === "light"
                        ? styles.edit_icon_light
                        : styles.edit_icon_dark
                    }
                  ></span>
                  <span>{t("edit")}</span>
                </span>
              )}
              <YearDropdown />
            </span>
          </div>
          <div
            className={
              isEmpty(div.innerText) || div.innerText === "undefined"
                ? `sub_heading ${styles.sub_heading}`
                : `sub_heading_with_goals ${styles.sub_heading}`
            }
          >
            {isEmpty(div.innerText) || div.innerText === "undefined"
              ? `${t("noGoalsMsg")} ${isEditEnable ? t("addGoalsMsg", { year: selectedYear }) : ""}`
              : t("PYGoalsMsg", {
                  year: `${t("py")} ${selectedYear.toString().slice(2)}`,
                })}
          </div>
          <div className={`goals_section ${styles.goals_section}`}>
            {isEditable ? (
              <FormatterBar
                id="goals"
                testId="goals_textarea_testID"
                label={`${selectedYear} ${t("goals")}`}
                editorData={updatedGoalsData}
                handleDataChange={(editorData: any) =>
                  setUpdatedGoalsData(editorData)
                }
                section={"goals"}
                isContentEditable={false}
                maxCharacter={2000}
              />
            ) : (
              <div
                className={`goals ${styles.goals}`}
                data-testid="goals_testID"
              >
                <HTMLtoText htmlContent={updatedGoalsData} />
              </div>
            )}
          </div>
          {isEditable && (
            <div className={styles.btns_wrapper}>
              <Button
                id="cancelButton"
                onClick={handleClickCancel}
                label={t("btnCancel")}
                style="unfilled"
              />
              <Button
                id="saveButton"
                onClick={handleClickSave}
                label={t("btnSave")}
                style="filled"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Goals;
