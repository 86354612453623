import {useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';

const useScroll = (
  refMap: Record<string, React.RefObject<HTMLDivElement>>,
  onSectionChange: (sectionId: string) => void,
) => {
  const handleScroll = useCallback(
    debounce(() => {
      const scrollPosition = window.scrollY;
      Object.keys(refMap).forEach((key) => {
        const sectionRef = refMap[key]?.current;
        if (sectionRef) {
          const sectionTop = sectionRef.offsetTop - 344;
          const sectionBottom = sectionTop + sectionRef.clientHeight;
          if (scrollPosition >= sectionTop && scrollPosition < sectionTop + 300) {
            onSectionChange(key);
          }
        }
      });
    }, 300),
    [onSectionChange, refMap]
  );

  const handleClick = (tabID: string) => {
    const targetRef = refMap[tabID]?.current;     
    if (targetRef) {
      const topOffset = targetRef.offsetTop - 350;
      const currectedOffset = topOffset >= 417 ? topOffset + 188 : topOffset;
      window.scrollTo({ top: currectedOffset, behavior: 'smooth' });
      onSectionChange(tabID);
    } 
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return { handleClick };
};

export default useScroll;
