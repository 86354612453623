import { TagValueProps } from "../types/types";

export const getPerformanceTag = (Props: TagValueProps) => {
  const { tagValue } = Props;

  switch (tagValue) {
    case 1:
      return "Good";
    case 2:
      return "Okay";
    case 3:
      return "Poor";
    default:
      return "";
  }
};
