import React from "react";
import styles from "./ToastMessage.module.scss";
import Icon from "../Icon/Icon";

interface ToastMessageProps {
  message: string | null;
  subText?: string;
  toastType?: "success" | "warn" | "error";
  showToast: boolean;
  setShowToast: (show: boolean) => void;
}

const ToastMessage: React.FC<ToastMessageProps> = ({
  message,
  subText,
  toastType = "success",
  showToast,
  setShowToast,
}) => {
  if (!showToast) return null;

  const toastIcons = {
    success: "circle_tickmark_green",
    warn: "warning_icon_orange",
    error: "warning_icon_square",
  };

  return (
    <div className={styles.toast_msg_container}>
      <div className={`${styles.toast_wrapper} ${styles[toastType]}`}>
        <div className={styles.toast_header}>
          <div className={styles.msg_section}>
            {toastIcons[toastType] && (
              <div className={styles.icon_section}>
                <Icon name={toastIcons[toastType]} size={16} />
              </div>
            )}
            <div className={styles.msg_txt}>{message}</div>
          </div>
          <div className={styles.close_icon} onClick={() => setShowToast(false)}>
            ✕
          </div>
        </div>
        {subText && <div className={styles.sub_text}>{subText}</div>}
      </div>
    </div>
  );
};

export default ToastMessage;
