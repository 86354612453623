import { createSlice } from "@reduxjs/toolkit";

interface ViewState {
viewId: {
  selectedViewId: number;
  defaultViewId:number;
	}}

const initialState: ViewState = {
	viewId: {
		selectedViewId: 1,
		defaultViewId:1,
		  }
		};

const viewIdSlice = createSlice({
  name: "viewIdDetails",
  initialState,
  reducers: {
    updateViewId: (state, action) => {
	  state.viewId = {...action.payload.viewIdDetails};
    },
  },
});

export const { updateViewId } = viewIdSlice.actions;
export default viewIdSlice.reducer;
