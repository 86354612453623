import React from "react";
import styles from './Tagtabs.module.scss';
import Icon from "../Icon/Icon";
import { useSelector } from "react-redux";


interface TagProps {
  label: string;
  onDelete?: () => void;
}

const Tag: React.FC<TagProps> = ({ label, onDelete }) => {
  const currentTheme = useSelector((state: any) => state.theme.currentTheme);
  return (
    <div className={`tag_container ${styles.tag_container}`}>
      <span className={`tag_label ${styles.tag_label}`}>{label}</span>
      <span className={styles.delete_tag}>
        <Icon
          name="cross_circle"
          color={currentTheme == "light" ? "#fff" : "#000"}
          onClick={onDelete}
          className={styles.cross_circle}
          size={18}
        />
      </span>
    </div>
  );
};

export default Tag;
