import React, { Component, ReactNode, useRef, useEffect } from "react";
import styles from "./Modal.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import closeIconLight from "../../../assets/images/LightTheme/close_icon_light.svg";
import closeIconDark from "../../../assets/images/DarkTheme/close_icon_dark.svg";

interface ModalProps {
  showDrawer: boolean;
  onClose?: () => void;
  children?: ReactNode;
  customStyle?: React.CSSProperties;
}

const Modal: React.FC<ModalProps> = ({ showDrawer, onClose, customStyle, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const handleKeyUp: EventListener = (e) => {
    if (
      e instanceof KeyboardEvent &&
      e.key === "Escape" &&
      showDrawer &&
      onClose
    ) {
      onClose();
    }
  };
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const closeIcon = currentTheme === "dark" ? closeIconDark : closeIconLight;

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [showDrawer, onClose, handleKeyUp]);

  const handleClickOutside = (e: React.MouseEvent) => {
    if (modalRef.current === e.target && onClose) {
      onClose();
    }
  };

  return (
  <>{showDrawer &&
      <div className={`modal_container ${styles.modal_container}`}>
      {showDrawer && (
        <div
          className={`modal_backdrop ${styles.modal_backdrop}`}
          
        ></div>
      )}
      <div
        className={`modal ${styles.modal}`}
        onClick={handleClickOutside}
        ref={modalRef}
        data-testid="modal_backdrop"
      >
        <div className={`modal_content ${styles.modal_content} ${showDrawer ? styles.active : ""}`} style={customStyle}>
          <div className={styles.close_icon} onClick={onClose}>
            <img src={closeIcon} alt="x" />
          </div>
          {children}
        </div>
      </div>
    </div>
  }</>
  );
};

export default Modal;
