import { createSlice } from "@reduxjs/toolkit";

import { ISuccessionPlanDocsDetails } from "../../types/types";

interface ISuccessionPlanDocsState {
  SuccessionPlanDocsList: ISuccessionPlanDocsDetails[];
}

const initialState: ISuccessionPlanDocsState = {
  SuccessionPlanDocsList: [],
};

const successionPlanDocSlice = createSlice({
  name: "successionPlanDoc",
  initialState,
  reducers: {
    updateSuccessionPlanDocList: (state, action) => {
      state.SuccessionPlanDocsList = action.payload;
    },
  },
});

export const { updateSuccessionPlanDocList } = successionPlanDocSlice.actions;
export default successionPlanDocSlice.reducer;
