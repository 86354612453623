import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";

const isLinkAccessible = (
  affiliateCodeOfLink: string,
  userAffiliateCode: string
) => {
  if (isEmpty(affiliateCodeOfLink) || affiliateCodeOfLink === userAffiliateCode)
    return true;
  return false;
};

export const getFilteredStaticLinkList = (
  rawData: externalSiteTypeFomAPI[] | undefined,
  userAffiliateCode: string
) => {
  let tempList: externalSiteTypeFomAPI[] = [];
  forEach(rawData || [], (dataItem) => {
    if (
      dataItem?.hasParameter === false &&
      isLinkAccessible(dataItem?.affiliateCode, userAffiliateCode)
    ) {
      tempList.push(dataItem);
    }
  });
  return tempList;
};

const checkForDynamicLink = (
  userAffiliateCode: string,
  rawData: externalSiteTypeFomAPI[] | undefined,
  systemCode = ""
) => {
  let foundItem: any = {};
  forEach(rawData, (dataItem) => {
    if (
      isLinkAccessible(dataItem?.affiliateCode, userAffiliateCode) &&
      dataItem.systemCode === systemCode &&
      dataItem.hasParameter
    ) {
      foundItem = dataItem;
    }
  });

  return foundItem;
};

export const getFilteredDynamicLinkList = (
  rawData: externalSiteTypeFomAPI[] | undefined,
  userAffiliateCode: string
) => {
  let tempDynamicList: externalSiteTypeFomAPI[] = [];
  let tempStaticList: externalSiteTypeFomAPI[] = getFilteredStaticLinkList(
    rawData,
    userAffiliateCode
  );

  forEach(tempStaticList, (staticListItem) => {
    let returnedItem = checkForDynamicLink(
      userAffiliateCode,
      rawData,
      staticListItem?.systemCode
    );
    if (isEmpty(returnedItem)) {
      tempDynamicList.push(staticListItem);
    } else {
      tempDynamicList.push(returnedItem);
    }
  });

  return tempDynamicList;
};
