import styles from "./Login.module.scss";
import { ReactComponent as LoginImageLight } from "../../../assets/images/LightTheme/login_image_light.svg";
import { ReactComponent as LoginImageDark } from "../../../assets/images/DarkTheme/login_image_dark.svg";
import { useAppSelector } from "../../../redux/hooks";
import Button from "../../common/Button/Button";
import { useTranslation } from "react-i18next";
import useLogin from "../../CustomHooks/useLogin";

function LoginComponent() {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const LoginImage =
    currentTheme === "light" ? LoginImageLight : LoginImageDark;
  const { onClickLoginBtn, isLoading, isError } = useLogin();

  return (
    <div className={`login_container ${styles.login_container}`}>
      <div className={styles.login_image_section}>
        <LoginImage />
      </div>
      <div className={styles.login_message_section}>
        <div className={styles.login_continue_text}>{t("loginToContinue")}</div>
        <div className={styles.sign_in_text}>{t("signInWithOkta")}</div>
      </div>
      <div className={styles.btn_area}>
        <Button
          id="loginButton"
          onClick={onClickLoginBtn}
          label={t("logIn")}
          style="unfilled"
          isLoading={isLoading}
        />
      </div>
      {isError && (
        <div className={`error_message ${styles.error_msg}`}>
          {t("something_went_wrong")}
        </div>
      )}
    </div>
  );
}

export default LoginComponent;
