import React from "react";
import styles from "./ErrorInPageScreen.module.scss";
import ErrorInPageDark from "../../../assets/images/DarkTheme/error_in_page_screen.svg";
import ErrorInPageLight from "../../../assets/images/LightTheme/error_in_page_screen.svg";
import TryAgainIconLight from "../../../assets/images/LightTheme/try_again.svg";
import TryAgainIconDark from "../../../assets/images/DarkTheme/try_again.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

type ComponentProps = {
  handleClickTryAgain: () => void;
};

const ErrorInPageScreen: React.FC<ComponentProps> = ({
  handleClickTryAgain,
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const TryAgainIcon =
    currentTheme === "light" ? TryAgainIconLight : TryAgainIconDark;
  const ErrorInPageScreen =
    currentTheme === "light" ? ErrorInPageLight : ErrorInPageDark;
  return (
    <div className={`error_in_page_wrapper ${styles.error_in_page_wrapper}`}>
      <img
        src={ErrorInPageScreen}
        alt="Error"
        className={styles.error_screen_img}
      />
      <div className={styles.error_screen_text}>
        {t("something_went_wrong")}
      </div>
      <div
        className={styles.try_again_cta}
        onClick={handleClickTryAgain}
      >
        <img
          src={TryAgainIcon}
          alt="refresh"
          className={styles.try_again_icon}
        />
        <span>{t("try_again")}</span>
      </div>
    </div>
  );
};

export default ErrorInPageScreen;
