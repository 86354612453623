import React, { ReactNode } from "react";
import styles from "./Tooltip.module.scss"; 

type TooltipProps = {
  content: ReactNode;
  children: ReactNode;
  width?: string; 
  height?: string; 
  top?: string;    
  left?: string;   
};

const Tooltip: React.FC<TooltipProps> = ({ content, children, width = "auto", height = "auto", top = "-40px", left = "50%" }) => {
  return (
    <div className={`tooltip_wrapper ${styles.tooltip_wrapper}`}>
      {children}
      <span 
        className={`tooltip ${styles.tooltip}`} 
        style={{ width, height, top, left }} // Apply dynamic top and left positions
      >
        <span className={`tooltip_text ${styles.tooltip_text}`}>{content}</span>
      </span>
    </div>
  );
};

export default Tooltip;
