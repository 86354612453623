import React, { useRef, useEffect, useState  } from "react";
import "./FormatterBar.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/types";
import styles from "./FormatterBar.module.scss";
import { useTranslation } from "react-i18next";
import 'quill-paste-smart';

interface formatterBarProps {
  id: string;
  testId?: string;
  label?: string;
  editorData: any;
  handleDataChange: (content: any, section: string) => void;
  section?: string;
  placeholder?: any;
  isContentEditable: boolean;
  maxCharacter: number;
}

const StyledEditorContainer = styled.div<{
  isFocused: boolean;
  currentTheme: string;
  section: string;
  toolbarId: string;
}>`
  .ql-toolbar.ql-snow {
    border: none;
    display: ${({ isFocused, section }) =>
      isFocused || section == "notes" ? "block" : "none"};
    border-bottom: ${({ currentTheme }) =>
      currentTheme == "light" ? "1px solid #E4E4E4" : "1px solid #000000"};
  }

  .ql-container.ql-snow {
    border: none;
    height: 140px;
  }

  .ql-editor {
    position: ${({ isFocused }) => (isFocused ? "static" : "relative")};
    top: ${({ isFocused, section }) =>
      isFocused || section == "notes" ? "0px" : "20px"};
  }
`;

const FormatterBar: React.FC<formatterBarProps> = ({
  id = "",
  testId = "text-area",
  label = "",
  editorData = "",
  handleDataChange,
  section = "",
  placeholder,
  isContentEditable,
  maxCharacter,
}) => {
  const currentTheme = useSelector(
    (state: RootState) => state.theme.currentTheme
  );
  const [isEditable, setIsEditable] = useState(isContentEditable);
  const [data, setData] = useState(editorData);
  const editorRef = useRef<ReactQuill | null>(null);
  const { t } = useTranslation();

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
  ];

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor();
      const toolbar = quill.getModule("toolbar").container as HTMLElement;
      const editorContainer = quill.root;
      if (toolbar && id) {
        toolbar.id = `${id}-toolbar`;
      }
      if (editorContainer && id) {
        editorContainer.id = `${id}-editor-container`;
      }
    }
  }, [id]);

  const handleContentChange = (content: any, delta: any, source: any, editor: any) => {
    if (data !== content) {
      if (editor.getLength() - 1 > maxCharacter) {
        const trimmedContent = editor.getText(0, maxCharacter);
        editorRef?.current?.getEditor().setText(trimmedContent);
        setData(trimmedContent);
      } else {
        setData(content);
        if (section) {
          handleDataChange(content, section);
        }
      }
    }
  };

  const handleFocus = () => {
    setIsEditable(true);
  };
  const handleBlur = () => {
    setIsEditable(false);
  };

  useEffect(() => {
    if (editorRef.current) {
      const quill = editorRef?.current?.getEditor();
      if (!isEditable) {
        quill.disable();
      } else {
        quill.enable();
      }
    }
  }, [isEditable]);

  const editorLightTheme = {
    height: "186px",
    border: !isEditable ? "1px solid #e4e4e4" : "2px solid #5969D6",
    borderRadius: "12px",
  };
  const editorDarkTheme = {
    height: "186px",
    border: !isEditable ? "1px solid #636366" : "2px solid #99B2F5",
    borderRadius: "12px",
    backgroundColor: !isEditable ? "#000000" : "#282828",
    color: "#AEAEB2",
  };

  return (
    <div className={`text_area_wrapper ${styles.text_area_wrapper}`}>
      <div
        className={`input_wrapper ${styles.input_wrapper}`}
        data-testid={testId}
      >
        <div
          className={`lbl_wrapper ${styles.lbl_wrapper}`}
          style={{
            display: isEditable || section === "notes" ? "none" : "block",
          }}
        >
          <div
            className={`lbl ${styles.lbl} ${isEditable ? "on_focus" : null}`}
          >
            {t(`${label}`)}
          </div>
        </div>
        <StyledEditorContainer
          isFocused={isEditable}
          currentTheme={currentTheme}
          section={section}
          toolbarId={id}
        >
          <ReactQuill
            id={id}
            data-testid={testId}
            ref={editorRef}
            theme="snow"
            value={data}
            placeholder={placeholder}
            onChange={handleContentChange}
            modules={modules}
            formats={formats}
            readOnly={!isEditable}
            style={currentTheme == "light" ? editorLightTheme : editorDarkTheme}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </StyledEditorContainer>
      </div>
    </div>
  );
};

export default FormatterBar;
