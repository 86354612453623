import React, { useState } from "react";
import styles from "./SummaryCard.module.scss";
import Benchmark from "../../../../../common/Benchmark/Benchmark";
import { useAppSelector } from "../../../../../../redux/hooks";
import GreenUpArrowLight from "../../../../../../assets/images/LightTheme/green_up_arrow.svg";
import GreenUpArrowDark from "../../../../../../assets/images/DarkTheme/green_up_arrow.svg";
import RedDownArrowLight from "../../../../../../assets/images/LightTheme/red_down_arrow.svg";
import RedDownArrowDark from "../../../../../../assets/images/DarkTheme/red_down_arrow.svg";
import { useTranslation } from "react-i18next";
import { getTranslationKeyByCode } from "../../../../../../utils/agcUtil";
import { CurrencyFormatter } from "../../../../../../utils/currencyFormatter";
import { NumberFormatter } from "../../../../../../utils/NumberFormatter";
interface SummaryCardProps {
  cardData: any;
  selectPill: (name: any) => void;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  cardData = {},
  selectPill,
}) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const greenUpIcon =
    currentTheme === "light" ? GreenUpArrowLight : GreenUpArrowDark;
  const redDownIcon =
    currentTheme === "light" ? RedDownArrowLight : RedDownArrowDark;
  const { t } = useTranslation();
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);

  return (
    <div
      className={`tile_main_wrapper ${styles.tile_main_wrapper}`}
      onClick={() =>
        selectPill(
          cardData?.kpiCode === "activationRate90"
            ? "activationRate:90|30|60"
            : cardData?.kpiCode
        )
      }
    >
      <div className={`tile_upper_section ${styles.tile_upper_section}`}>
        <div className={`tile_header ${styles.tile_header}`}>
          <span>
            {t(`kpiTitles.${getTranslationKeyByCode(cardData?.kpiCode)}`, {
              numberOfDays: 90,
            })}
          </span>
        </div>
        <div className={`tile_body ${styles.tile_body}`}>
          <span className={`tile_value ${styles.tile_value}`}>
            {cardData?.kpiValue === null
              ? t("noDataAvailable")
              : cardData?.kpiDataType === "number"
                ? NumberFormatter(userAffiliateCode, cardData?.kpiValue)
                : cardData?.kpiCode === "totalIncome"
                  ? String(
                      CurrencyFormatter(
                        cardData?.kpiCurrency,
                        cardData?.kpiValue,
                        t("noDataAvailable")
                      )
                    ).replace(/\.00$/, "")
                  : CurrencyFormatter(
                      cardData?.kpiCurrency,
                      cardData?.kpiValue,
                      t("noDataAvailable")
                    )}
            {cardData?.kpiValue !== null &&
              cardData?.kpiDataType === "percentage" &&
              "%"}
          </span>
          {cardData?.kpiVarianceValue !== null && (
            <span
              className={`tile_change_value_wrapper ${styles.tile_change_value_wrapper}`}
            >
              <span className={`${styles.change_arrow}`}>
                <img
                  src={
                    cardData?.kpiVarianceValue >= 0 ? greenUpIcon : redDownIcon
                  }
                  alt="Arrow"
                  className={`${styles.benchmark_pin}`}
                />
              </span>
              <span
                className={`change_value ${cardData?.kpiVarianceValue >= 0 ? "positive" : "negative"} ${styles.change_value}`}
              >
                {cardData?.kpiVarianceDataType === "currency" &&
                  cardData?.kpiCurrency === "USD" &&
                  "$"}
                {Math.abs(cardData.kpiVarianceValue)}
                {cardData?.kpiVarianceDataType === "percentage" && "%"}
              </span>
              <span className={`${styles.change_unit}`}>
                {cardData?.kpiVarianceComparisonCriteria}
              </span>
            </span>
          )}
        </div>
      </div>

      <hr className={`separator_line ${styles.separator_line}`} />

      <div className={`tile_lower_section ${styles.tile_lower_section}`}>
        {cardData?.benchmark !== null &&
          (!cardData?.benchmark ? (
            <div className={`${styles.lower_section_title}`}>
              {/* NOT APPLICABLE FOR BENCHMARKING */}
              {t("noBenchmark")}
            </div>
          ) : (
            <Benchmark
              minValue={cardData?.benchmark?.start}
              midValue={cardData?.benchmark?.mid}
              maxValue={cardData?.benchmark?.end}
              benchmarkValue={cardData?.kpiValue}
              quartileLine={cardData?.benchmark?.other}
              isPercentValue={cardData?.benchmark?.isPercentValue}
              benchmarkDataType={cardData?.benchmarkDataType}
              currency={cardData?.kpiCurrency}
            />
          ))}
      </div>
    </div>
  );
};

export default SummaryCard;
