export const getURLQueryParams = (rawData?: string): Map<string, string> => {
  if (!rawData || typeof rawData !== "string" || rawData?.length < 2) {
    return new Map();
  }

  const paramsMap: [string, string][] = rawData
    .substr(1)
    .split("&")
    .map((item) => {
      const newItem = item.split("=");
      return [newItem[0], newItem[1]];
    });

  return new Map(paramsMap);
};