import { createSlice } from "@reduxjs/toolkit";
import { loadStateFromSessionStorage, saveStateToSessionStorage } from "../../utils/sessionStorageUtility";

const storedUserDetails = loadStateFromSessionStorage();
const initialState: any = {
  user: {
    userAffiliateCode: localStorage?.getItem('userAffiliateCode') || '',
    userCountryCode: localStorage?.getItem('userCountryCode') || '',
    affiliateCode: (storedUserDetails && storedUserDetails.affiliateCode) || "",
    aboNumber: (storedUserDetails && storedUserDetails.aboNumber) || "",
    isAccessibleAPFeature: localStorage?.getItem('isAccessibleAPFeature') === "true",
  },
  history: []
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setInitialUserDetails: (state, action) => {
      //to store the details initially
      state.history.push({ ...state.user });
      state.user = { ...action.payload.userDetails };
    },
    restoreUserDetails: (state: any) => {
      const previousState = state.history.pop();
      if (previousState) {
        state.user = previousState;
        saveStateToSessionStorage(previousState);
      }
    },
  },
});

export const userDetailSelector = (state: any) => state.userdetails.user;

export const { setInitialUserDetails, restoreUserDetails } = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
