import React, { useEffect, useState } from "react";
import styles from "./APProfileSubheader.module.scss";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "../../../../redux/hooks";
import { getInitials } from "../../../../utils/common";
import { useTranslation } from "react-i18next";
import { useGetAPSummaryQuery } from "../../../../redux/services/apSubHeaderAPI";
import { useGetAboAccountManagerQuery } from "../../../../redux/services/aboInfoBannerDetailsAPI";

type APSummaryDataType = {
  code: string;
  name: string;
  localName: string;
  accountManagerNativeId: string;
  affiliateCode: string;
  marketCountryCode: string;
  marketName: string;
};

const APProfileSubheaderComponent: React.FC = () => {
  const { t } = useTranslation();
  const selectAP = useAppSelector((state: any) => state.apDetails.selectedAP);
  const [APSummaryData, setAPSummaryData] = useState<APSummaryDataType | null>(
    null
  );

  const {
    isFetching: isLoadingSummary,
    isError,
    data,
  } = useGetAPSummaryQuery(
    {
      apCode: selectAP?.code ?? "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  //Account manager API - depends on the Summary details API for the native id
  const { data: acconutManagerDetails, isFetching: isLoadingAccountManager } =
    useGetAboAccountManagerQuery(
      { nativeId: data?.body?.accountManagerNativeId },
      {
        skip: isEmpty(data?.body?.accountManagerNativeId),
        refetchOnMountOrArgChange: true,
      }
    );

  useEffect(() => {
    setAPSummaryData(data?.body);
  }, [data]);

  const accountManagerDetails: any =
    isEmpty(data?.body?.accountManagerNativeId) || isLoadingAccountManager
      ? null
      : acconutManagerDetails?.body;

  const accountManager = accountManagerDetails?.fullName;
  const nativeId = accountManagerDetails?.nativeId;

  return (
    <div
      className={`ap_view_profile_container ${styles.ap_view_profile_container}`}
    >
      {isLoadingSummary ? (
        <div className={`${styles.ap_view_profile_wrapper} animate`}></div>
      ) : isError ? (
        <div data-testId="error_testID" className={styles.error_text}>
          {t("no_info_available")}
        </div>
      ) : (
        <div
          className={`ap_view_profile_wrapper ${styles.ap_view_profile_wrapper}`}
        >
          <div className={styles.profile_inner_wrapper}>
            <div className={styles.profile_main_info_wrapper}>
              <div
                className={`ap_profile_image_wrapper ${styles.profile_image_wrapper}`}
                style={{ background: "#5969D6" }}
              >
                <div className={styles.profile_image}>
                  {getInitials(APSummaryData?.name || "")}
                </div>
              </div>
              <div className={styles.abo_info_name_number}>
                <div className={`abo_name ${styles.abo_name}`}>
                  {APSummaryData?.name || ""}
                </div>
                <div className={styles.ap_country}>
                  <span className={`abo_number ${styles.abo_number}`}>
                    {APSummaryData?.marketName || ""}
                  </span>
                </div>
              </div>
            </div>
            <div className={`${styles.ap_manager_details_wrapper}`}>
              <div
                className={`field_title_wrapper ${styles.field_title_wrapper}`}
              >
                <span
                  className={`${styles.data_item} ${styles.account_name}`}
                  data-testid="account_manager_testID"
                >
                  {t("AP_info.accountManagerName")}
                </span>
              </div>
              <div
                className={`field_data_wrapper ${styles.field_data_wrapper}`}
              >
                <span
                  className={`${styles.data_item} ${styles.data_value} ${styles.account_name}`}
                  title={
                    accountManager ? accountManager : nativeId ? nativeId : "-"
                  }
                >
                  {accountManager ? accountManager : nativeId ? nativeId : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default APProfileSubheaderComponent;
