// Function to concatenate strings with comma separation
export const concatenateWithComma = (...strings: string[]): string => {

  let result = "";
  strings && strings?.forEach((str, index) => {
    if (str && str !== " ") {
      result += str;
      // Add comma if next string is present
      if (index < strings?.length - 1 && strings[index + 1]) {
        result += ", ";
      }
    }
  });
  return result;
};
