import React from 'react';
import styles from './KPIComingSoon.module.scss';
import { useAppSelector } from '../../../redux/hooks';
import KPIDark from "../../../assets/images/DarkTheme/kpi_coming_soon.svg";
import KPILight from "../../../assets/images/LightTheme/kpi_coming_soon.svg";

interface KPIPlaceholderProps {
  title?: string;
}

const KPIComingSoon: React.FC<KPIPlaceholderProps> = ({ title}) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme); 
  const kpiComingSoon = currentTheme === "dark" ? KPIDark : KPILight;
  return (
    <div className={`kpi_coming_soon ${styles.kpi_coming_soon}`}>
      <div className={styles.kpi_icon}>
        <img src={kpiComingSoon} alt="coming soon" />
      </div>
      <p className={`kpi_text ${styles.kpi_text}`}>{title}</p>
    </div>
  );
};

export default KPIComingSoon;
