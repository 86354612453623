import { currencyLocaleMappings } from '../constants/dataConstants';
export const CurrencyFormatter = (currency: string, revenue: number, noDataAvailable?: string ) => {
	// Define Thai currency codes
	const thaiCurrencyCodes = ["BHT", "TBH", "THB"];
	const currencyCode = thaiCurrencyCodes.includes(currency) ? "THB" : currency;
  const locale = currencyLocaleMappings[currencyCode] || "en-US";

	if (revenue === null || revenue === undefined || isNaN(revenue)) {
		console.error("Invalid revenue value:", revenue);
		return noDataAvailable || "No data available";
	}

	return currency ?
	   revenue?.toLocaleString(locale, {
		  style: 'currency',
		  currency: currencyCode,
		  maximumFractionDigits: 2,
		  minimumFractionDigits: 2,
		}) : revenue
	 };