import { useEffect, useState } from "react";
const getOnLineStatus = () =>
  typeof navigator !== "undefined" && typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;

const useConnectivity = () => {
  const [isOnline, setIsOnline] = useState<boolean | null>(getOnLineStatus());

  useEffect(() => {
    if (typeof window !== "undefined") {
      const setOnline = () => setIsOnline(true);
      const setOffline = () => setIsOnline(false);

      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);

      return () => {
        window.removeEventListener("online", setOnline);
        window.removeEventListener("offline", setOffline);
      };
    }
  }, []);
  return {
    isOnline,
  };
};

export default useConnectivity;
