import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import forEach from "lodash/forEach";
import { useSearchParams } from "react-router-dom";
import config from "../../../config/config";
import authService from "../../../config/auth.service";
import axiosInstance from "../../../config/interceptors/axios.interceptor";
import {
  countryMappings,
  currencyMappings,
} from "../../../constants/dataConstants";
import { useSelector } from "react-redux";

export const TokenHandler = () => {
  const [queryParameters] = useSearchParams();
  const selectedLang = useSelector(
    (state: any) => state.language.currentLanguage
  );

  const checkForAPFeatureAccess = (decodedAccessToken: any) => {
    forEach(decodedAccessToken?.Permission, (feature_obj) => {
      if (feature_obj?.f === "APProfile" && feature_obj?.r === 1) {
        localStorage.setItem("isAccessibleAPFeature", "true");
      }
    });
  };

  useEffect(() => {
    const getTokenExchange = () => {
      const lgsAuthorizationCode = queryParameters.get("code");
      const id = queryParameters.get("id");
      const url = `${config.appUrl}/auth/token?code=${lgsAuthorizationCode}&id=${id}`;

      try {
        axiosInstance.get(url).then((res) => {
          if (res && res.data && res.data.statusCode === HttpStatusCode.Ok) {
            const token = authService.handleTokenResponse(res.data);
            const decodedAccessToken = authService.decodeAccessToken(token);
            localStorage.setItem(
              "userAffiliateCode",
              decodedAccessToken?.AffiliateCode ?? ""
            );
            localStorage.setItem(
              "userCountryCode",
              decodedAccessToken?.CountryCode ?? ""
            );
            if (token) {
              checkForAPFeatureAccess(decodedAccessToken);
              const userNativeId = localStorage.getItem("auth-nativeId");
              window?.heap?.identify(userNativeId?.toLowerCase());

              const siteCountryCode = localStorage.getItem("userCountryCode");
              const siteTags = {
                site_country: siteCountryCode
                  ? countryMappings[siteCountryCode]
                  : "",
                site_currencyCode: siteCountryCode
                  ? currencyMappings[siteCountryCode]
                  : "",
                site_language: selectedLang,
              };

              window.utag_data = siteTags;

              window.location.href = "/myaccount";
            } else {
              window?.heap?.resetIdentity();
              window.location.href = "/login";
            }
          }
        });
      } catch (err) {
        console.log(`Get token exchange error: ${err}`);
      }
    };

    getTokenExchange();
  }, []);

  return <div>Loading.....</div>;
};
