import { createSlice } from "@reduxjs/toolkit";
const currentYear = new Date().getFullYear();

interface YearState {
  selectedYear: string;
}

const initialState: YearState = {
  selectedYear: currentYear.toString(),
};

const yearSlice = createSlice({
  name: "selectedYear",
  initialState,
  reducers: {
    updateYear: (state, action) => {
      state.selectedYear = action.payload;
    },
  },
});

export const { updateYear } = yearSlice.actions;
export default yearSlice.reducer;
