import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import styles from "./MyAccount.module.scss";
import isEmpty from "lodash/isEmpty";
import AccountTableContainer from "../MyAccount/AccountDetails/AccountDetails";
import Modal from "../common/Modal/Modal";
import CustomizeViewModal from "../common/CustomizeViewModal/CustomizeViewModal";
import CustomizeDarkIcon from "../../assets/images/DarkTheme/setting.svg";
import CustomizeLightIcon from "../../assets/images/LightTheme/setting_icon.svg";
import SeperatorLineDark from "../../assets/images/DarkTheme/seperator_line.svg";
import SeperatorLineLight from "../../assets/images/LightTheme/seperator_line.svg";
import { useDispatch } from "react-redux";
import { updateViewId } from "../../redux/slices/viewSlice";
import { useGetAccountListMutation } from "../../redux/services/myAccountAPI";
import MyAccountsLoader from "../common/Loaders/MyAccountsLoader/MyAccountsLoader";
import {
  useGetViewsListQuery,
  useGetViewDetailsQuery,
} from "../../redux/services/customizeViewAPI";
import ErrorHandlingComponent from "../ErrorHandling/ErrorHandling";
import { isValidResponse } from "../../utils/validateAPIResponse";
import { disableEnableScroll } from "../../utils/disableEnableScroll";

const MyAccount: React.FC = () => {
  const { t } = useTranslation();
  const currentTheme: any = useAppSelector(
    (state: any) => state.theme.currentTheme
  );

  const [closingMonth, setClosingMonth] = useState<string>('');

  const viewId = useAppSelector((state: any) => state.viewIdDetails?.viewId);
  const defaultViewId = viewId?.defaultViewId
    ? viewId?.defaultViewId
    : viewId?.selectedViewId;

  const [savedViews, setSavedViews] = useState<ViewListDataType[]>([]);

  const CustomizeIcon =
    currentTheme === "dark" ? CustomizeDarkIcon : CustomizeLightIcon;
  const SeperatorIcon =
    currentTheme === "dark" ? SeperatorLineDark : SeperatorLineLight;
  const dispatch = useDispatch();
  const [accountTableData, setAccountTableData] = useState<any>({});
  const [isAPIError, setIsAPIError] = useState<boolean>(false);
  const [viewDetails, setViewDetails] = useState<any>(null);

  // Get the list of all view
  const {
    isLoading: viewListLoading,
    data: viewListData,
    isError: viewListError,
    isFetching: viewListFetching,
  } = useGetViewsListQuery({ type: "my-abo" }, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setSavedViews(viewListData?.body || []);
  }, [viewListData]);

  useEffect(() => {
    if (savedViews && savedViews.length >= 1 || (!viewListData || defaultViewId == 0 && viewId?.selectedViewId === 0)) {
      const view = savedViews.filter((view) => view._default);
      const defaultViews = view.sort(
        (a, b) =>
          new Date(b.audit.updatedDate).getTime() -
          new Date(a.audit.updatedDate).getTime()
      );

      // Get the most recent default view
      const recentDefaultView = defaultViews[0];
      if ((view && viewId.defaultViewId) || (!viewListData || defaultViewId == 0 && viewId?.selectedViewId === 0)) {
        dispatch(
          updateViewId({
            ...viewId,
            viewIdDetails: {
              defaultViewId: recentDefaultView?.viewId || 1,
              selectedViewId: null,
            },
          })
        );
      }
    }
  }, [savedViews]);


  // View details API
  const { data: viewDetailsData,
    isError: viewDetailsError,
    isLoading: viewDetailsLoading,
    isFetching: viewDetailsFetching, } = useGetViewDetailsQuery(
      {
        viewId: defaultViewId,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: !viewListData || defaultViewId == 0,
      }
    );

  useEffect(() => {
    if (viewDetailsData) {
      setViewDetails(viewDetailsData?.body?.viewSetting)
    }
  }, [viewDetailsData]);


  //Account list API
  const [accountList, { isLoading: accountListLoading,
    isError: accountListError,
    data: accountListData,
  }] =
    useGetAccountListMutation();

  const handleAccounList = async () => {
    if (!viewDetails) {
      return;
    }
    const response: any = await accountList({
      payload: {
        viewId: defaultViewId,
        viewSetting: defaultViewId == 0 ? viewDetails : null
      },
      pageNumber: 1
    });

  };

  useEffect(() => {
    handleAccounList();
  }, [viewDetails])

  const handleAPIError = () => {
    setIsAPIError(true);
    setAccountTableData({});
    setClosingMonth('');
  };

  useEffect(() => {
    accountListError && handleAPIError();
  }, [accountListError]);

  useEffect(() => {
    if (!isEmpty(accountListData)) {
      if (isValidResponse(accountListData?.statusCode)) {
        setAccountTableData(accountListData);
        let closingMonthsFormatted = accountListData?.body?.closingMonth ? `${accountListData?.body?.closingMonth.slice(-2)}/${accountListData?.body?.closingMonth.slice(0, 4)}` : '-'
        setClosingMonth(closingMonthsFormatted);
      } else {
        handleAPIError();
      }
    }
  }, [accountListData, accountListLoading]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleModalToggle = () => {
    setModalOpen(!modalOpen);
  };

  useEffect(() => {
    disableEnableScroll(modalOpen);
  }, [modalOpen])

  const handleViewSelection = (selectedView: any) => {
    dispatch(
      updateViewId({
        ...viewId,
        viewIdDetails: { defaultViewId: selectedView, selectedViewId: null },
      })
    );
  };
  return (
    <div
      className={`myAccountContainer ${styles.myaccount_Container}`}
      data-testid="my_account_testId"
    >
      {accountListLoading ||
        viewListLoading ||
        viewListFetching ||
        viewDetailsLoading ? (
        <MyAccountsLoader num={6} />
      ) : isAPIError ? (
        <ErrorHandlingComponent error={"genericError"} />
      ) : (
        <>
          <div className={`section_title_head ${styles.section_title_head}`}>
            {t("my_account")}
          </div>
          {closingMonth && accountTableData && Object.keys(accountTableData)?.length > 0 && !isEmpty(accountTableData?.body?.abos) && <div className={`section_sub_heading ${styles.sub_heading}`}>
            {closingMonth ? `${t("Last_closed_month")} ${closingMonth}` : ""}
          </div>}
          <div className={styles.btn_wrapper}>
            <div className={styles.customize_btn} onClick={handleModalToggle}>
              <img
                src={CustomizeIcon}
                alt="settings"
                className={styles.setting_icon}
              />
              <span className={styles.label}>{t("customize")}</span>
            </div>
            <img src={SeperatorIcon} alt="seperator" />
            <div
              className={`${defaultViewId === 1 ? "selected_view_btn" : ""} ${styles.view_btn}`}
              onClick={() => handleViewSelection(1)}
            >
              <span className={styles.label} data-testid="default_view_testId">{t("default_view")}</span>
            </div>
            {savedViews &&
              savedViews.length >= 1 &&
              savedViews?.map((view: any) => (
                <div
                  className={`${defaultViewId === view?.viewId ? "selected_view_btn" : ""} ${styles.view_btn}`}
                  onClick={() => handleViewSelection(view?.viewId)}
                  key={view?.viewId}
                >
                  <span className={styles.label}>{view?.name}</span>
                </div>
              ))}
          </div>
          <AccountTableContainer
            data={accountTableData}
            viewDetails={viewDetails}
          />
        </>
      )}

      {modalOpen && (
        <Modal showDrawer={modalOpen} onClose={handleModalToggle}>
          <CustomizeViewModal
            onClose={handleModalToggle}
            savedViews={savedViews}
            setViewDetails={setViewDetails}
          />
        </Modal>
      )}
    </div>
  );
};

export default MyAccount;
