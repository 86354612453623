import { createSlice } from "@reduxjs/toolkit";

interface closingMonthState {
    businessPerformanceClosingMonth: string,
}

const initialState: closingMonthState = {
    businessPerformanceClosingMonth: '',
};

const closingMonthSlice = createSlice({
    name: "businessPerformanceClosingMonth",
    initialState,
    reducers: {
        updateBusinessPerformanceClosingMonth: (state, action) => {
            state.businessPerformanceClosingMonth = action.payload;
        },
    },
});

export const { updateBusinessPerformanceClosingMonth } = closingMonthSlice.actions;
export default closingMonthSlice.reducer;
