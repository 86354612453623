import React, { useEffect, useState } from "react";
import styles from "./Summary.module.scss";
import SummaryCard from "./SummaryCard/SummaryCard";
import KPIComingSoon from "../../../../common/KPIComingSoon/KPIComingSoon";
import { useGetBusinessPerformanceSummaryQuery } from "../../../../../redux/services/businessPerformaceAPI";
import { useAppSelector } from "../../../../../redux/hooks";
import { isEmpty } from "lodash";
import { isValidResponse } from "../../../../../utils/validateAPIResponse";
import { updateBusinessPerformanceClosingMonth } from "../../../../../redux/slices/businessPerformanceClosingMonthSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ErrorInPageScreen from "../../../../common/ErrorInPageScreen/ErrorInPageScreen";
import ContactInfoLoader from "../../../PersonalInformation/ContactCardInformation/ContactInfoLoader/ContactInfoLoader";

interface summaryProps {
  selectPill: (name: any) => void;
  category: string;
  kpiList: kpiListType[];
}

const Summary: React.FC<summaryProps> = ({ selectPill, category, kpiList }) => {
  const dispatch = useDispatch();
  const [kpiCodeList, setKpiCodeList] = useState<any>();
  const [isAPIError, setIsAPIError] = useState(false);
  const [summaryCardList, setSummaryCardList] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isEmpty(kpiList)) {
      const KPICodes = kpiList.map((item) => {
        return { code: item?.code === "activationRate:90|30|60" ? "activationRate90" : item?.code};
      });
      setKpiCodeList(KPICodes);
    }
  }, [kpiList]);

  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const {
    isFetching,
    data: summaryCardsResponse,
    isError: isErrorInSummaryCard,
  } = useGetBusinessPerformanceSummaryQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
      payload: { kpis: kpiCodeList },
    },
    {
      skip:
        userDetails.affiliateCode === 0 ||
        userDetails.aboNumber === 0 ||
        isEmpty(kpiCodeList) ||
        isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setSummaryCardList([]);
  };

  useEffect(() => {
    isErrorInSummaryCard && handleAPIError();
  }, [isErrorInSummaryCard]);

  useEffect(() => {
    if (!isEmpty(summaryCardsResponse)) {
      if (isValidResponse(summaryCardsResponse?.statusCode)) {
        setIsAPIError(false);
        setSummaryCardList(summaryCardsResponse?.body?.kpis);
        let closingMonthsFormatted = summaryCardsResponse?.body?.closingMonth
          ? `${summaryCardsResponse?.body?.closingMonth.slice(-2)}/${summaryCardsResponse?.body?.closingMonth.slice(0, 4)}`
          : "-";
        dispatch(updateBusinessPerformanceClosingMonth(closingMonthsFormatted));
      } else {
        handleAPIError();
      }
    }
  }, [summaryCardsResponse, isFetching]);

  return (
    <>
      {isFetching ? (
        <ContactInfoLoader cardNumber={4} />
      ) : isAPIError ? (
        <ErrorInPageScreen
          handleClickTryAgain={() => {
            setIsAPIError(false);
          }}
        />
      ) : (
        <div className={`summary_section ${styles.summary_section}`}>
          {summaryCardList?.map(
            (tile: any, index: React.Key | null | undefined) => (
              <div
                key={index}
                className={`tile_container ${styles.tile_container}`}
              >
                <SummaryCard cardData={tile} selectPill={selectPill} />
              </div>
            )
          )}
          <KPIComingSoon title={t("kpiCommingSoon")} />
        </div>
      )}
    </>
  );
};

export default Summary;
