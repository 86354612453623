import map from "lodash/map";
import { useTranslation } from "react-i18next";

const KPIsListDataMassager = (rawData: any = []) => {
  const { t } = useTranslation();
  const dataToBeReturn = map(rawData, (dataItem) => {
    const newDataItem = {
      code: dataItem?.code ?? "",
      shortName:
        dataItem?.code === "activationRate:90|30|60"
          ? t(`kpiLabels.activationRate`)
          : t(`kpiLabels.${dataItem?.code}`),
      longName:
        dataItem?.code === "activationRate:90|30|60"
          ? t(`kpiTitles.activationRate`)
          : t(`kpiTitles.${dataItem?.code}`),
      description:
        dataItem?.code
          ? t(`kpiDescription.${dataItem?.code}`)
          : "-",
      type: dataItem?.type ?? "",
    };
    return newDataItem;
  });
  return dataToBeReturn;
};

export default KPIsListDataMassager;
