import styles from "./MyAccountsLoader.module.scss";

type ComponentProps = {
  num: number;
};

function MyAccountsLoader({ num }: ComponentProps) {
  let arr: number[] = new Array(num).fill(0);

  return (
    <div className={styles.my_accounts_loading_container}>
      <div className={styles.btn_wrapper}>
        <div className={`${styles.customize_btn_large} animate`}></div>
        <div className={`${styles.customize_btn_small} animate`}></div>
        <div className={`${styles.customize_btn_medium} animate`}></div>
      </div>
      <div className={`${styles.table_heading} animate`}></div>
      {arr.map((_, index) => (
        <div className={`${styles.table_row} animate`} key={index}></div>
      ))}
    </div>
  );
}

export default MyAccountsLoader;
