import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  selectedAP: {}
};

export const apDetailsSlice = createSlice({
  name: "apDetails",
  initialState,
  reducers: {
    setAPDetails: (state, action) => {
      state.selectedAP = { ...action.payload };
    }
  },
});

export const { setAPDetails } = apDetailsSlice.actions;
export default apDetailsSlice.reducer;
