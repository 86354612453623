import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ThemeState {
  currentTheme: "light" | "dark";
}
const initialState: ThemeState = {
  currentTheme: localStorage.getItem("theme") === "dark" ? "dark" : "light", // Persist theme preference
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme(state) {
      state.currentTheme = state.currentTheme === "light" ? "dark" : "light";
      localStorage.setItem("theme", state.currentTheme); // Store in localStorage
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
