import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userDetailsSlice from "./slices/userDetailsSlice";
import themeReducer from "./slices/themeSlice";
import tagReducer from "./slices/tagSlice";
import languageReducer from "./slices/languageSlice";
import contactCardReducer from "./slices/contactCardSlice";
import yearReducer from "./slices/selectedYearSlice";
import viewReducer from "./slices/viewSlice";
import apDetailsReducer from "./slices/apslice";
import { aboBackgroundDetailsAPI } from "./services/aboBackgroundDetailsAPI";
import { aboContactCardDetailsAPI } from "./services/aboContactCardDetailsAPI";
import successionPlanReducer from "./slices/successionDocsSlice";
import { successionPlanDocsListAPI } from "./services/successionPlanAPI";
import { actionPlanDocsListAPI } from "./services/actionPlansDocumentAPI";
import { aboInfoBannerDetailsAPI } from "./services/aboInfoBannerDetailsAPI";
import { optionMasterAPI } from "./services/optionMasterAPI";
import { goalsAPI } from "./services/goalsAPI";
import { progressNotesAPI } from "./services/progressNotesAPI";
import { apNotesAPI } from "./services/apNotesAPI";
import { myAccountListAPI } from "./services/myAccountAPI";
import { globalSearchAPI } from "./services/globalSearchAPI";
import { legAnalysisAPI } from "./services/legAnalysisAPI";
import { customizeViewAPI } from "./services/customizeViewAPI";
import { apInformationDocsListAPI } from "./services/apInformationdocumentAPI";
import { apDropdownAPI } from "./services/apDropdownAPI";
import { apBackgroundDetailsAPI } from "./services/apBackgroundDetailsAPI";
import { apSubheaderAPI } from "./services/apSubHeaderAPI";
import { kpiSettingsByTypeAPI } from "./services/kpiSettingsByTypeAPI";
import { businessPerformanceAPI } from "./services/businessPerformaceAPI";
import customViewKpiListSlice from "./slices/customisedViewKpiList";
import closingMonthReducer from "./slices/businessPerformanceClosingMonthSlice";

const combinedReducer = combineReducers({
  userDetails: userDetailsSlice,
  theme: themeReducer,
  tag: tagReducer,
  contactCard: contactCardReducer,
  selectedYear: yearReducer,
  successionPlanDoc: successionPlanReducer,
  language: languageReducer,
  viewIdDetails: viewReducer,
  apDetails: apDetailsReducer,
  businessPerformanceClosingMonth: closingMonthReducer,
  customViewConfigKPIList: customViewKpiListSlice,
  // `rtk-query` api reducers
  [aboBackgroundDetailsAPI.reducerPath]: aboBackgroundDetailsAPI.reducer,
  [aboContactCardDetailsAPI.reducerPath]: aboContactCardDetailsAPI.reducer,
  [successionPlanDocsListAPI.reducerPath]: successionPlanDocsListAPI.reducer,
  [aboInfoBannerDetailsAPI.reducerPath]: aboInfoBannerDetailsAPI.reducer,
  [optionMasterAPI.reducerPath]: optionMasterAPI.reducer,
  [actionPlanDocsListAPI.reducerPath]: actionPlanDocsListAPI.reducer,
  [goalsAPI.reducerPath]: goalsAPI.reducer,
  [progressNotesAPI.reducerPath]: progressNotesAPI.reducer,
  [apNotesAPI.reducerPath]: apNotesAPI.reducer,
  [myAccountListAPI.reducerPath]: myAccountListAPI.reducer,
  [globalSearchAPI.reducerPath]: globalSearchAPI.reducer,
  [legAnalysisAPI.reducerPath]: legAnalysisAPI.reducer,
  [customizeViewAPI.reducerPath]: customizeViewAPI.reducer,
  [apInformationDocsListAPI.reducerPath]: apInformationDocsListAPI.reducer,
  [apDropdownAPI.reducerPath]: apDropdownAPI.reducer,
  [apBackgroundDetailsAPI.reducerPath]: apBackgroundDetailsAPI.reducer,
  [apSubheaderAPI.reducerPath]: apSubheaderAPI.reducer,
  [kpiSettingsByTypeAPI.reducerPath]: kpiSettingsByTypeAPI.reducer,
  [businessPerformanceAPI.reducerPath]: businessPerformanceAPI.reducer,
});

const masterReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: masterReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(aboContactCardDetailsAPI.middleware)
      .concat(aboBackgroundDetailsAPI.middleware)
      .concat(successionPlanDocsListAPI.middleware)
      .concat(aboInfoBannerDetailsAPI.middleware)
      .concat(optionMasterAPI.middleware)
      .concat(actionPlanDocsListAPI.middleware)
      .concat(goalsAPI.middleware)
      .concat(progressNotesAPI.middleware)
      .concat(apNotesAPI.middleware)
      .concat(myAccountListAPI.middleware)
      .concat(globalSearchAPI.middleware)
      .concat(legAnalysisAPI.middleware)
      .concat(customizeViewAPI.middleware)
      .concat(apInformationDocsListAPI.middleware)
      .concat(apDropdownAPI.middleware)
      .concat(apBackgroundDetailsAPI.middleware)
      .concat(apSubheaderAPI.middleware)
      .concat(kpiSettingsByTypeAPI.middleware)
      .concat(businessPerformanceAPI.middleware),
});

export const makeStore = () => store;

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState}
export type AppDispatch = typeof store.dispatch;
