import React, { useEffect, useRef, useState } from "react";
import styles from "./UserProfileDropdown.module.scss";
import { useTranslation } from "react-i18next";
import { getInitials } from "../../../utils/common";
import { useNavigate } from "react-router-dom";
import { DataItem } from "../../../types/types.d";
import { setInitialUserDetails } from "../../../redux/slices/userDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import SearchLoader from "../Loaders/SearchLoader/SearchLoader";
import { saveStateToSessionStorage } from "../../../utils/sessionStorageUtility";
import { getCountryNameFromCode } from "../../../utils/awardIconMapping";

interface UserProfileDropdownProps {
  searchTerm: string;
  handleSearchTerm: ()=> void;
  data: DataItem[];
  isLoadingSearchABO: boolean;
}

const UserProfileDropdown: React.FC<UserProfileDropdownProps> = ({ searchTerm, handleSearchTerm, data, isLoadingSearchABO }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const userDetails = useSelector((state: any) => state.userDetails.user);

  const getHighlightedText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {parts.map((part, i) => (
          <span
            key={i}
            className={part?.toLowerCase() === highlight?.toLowerCase() ? styles.bold_text : ''}
          >
            {part}
          </span>
        ))}
      </span>
    );
  };

  const handleProfileClick = (profileData: any) => {
    if (profileData.aboNumber && profileData.affiliateCode) {
      dispatch(setInitialUserDetails({
        userDetails: {
          ...userDetails,
          affiliateCode: profileData.affiliateCode,
          aboNumber: profileData.aboNumber,
        }
      }));
      setIsDropdownOpen(false);
      saveStateToSessionStorage({
        affiliateCode: profileData.affiliateCode,
        aboNumber: profileData.aboNumber,
      });
      navigate(`/profile`);

      const accountData = {
        abo_account: profileData.aboNumber,
        abo_country: profileData?.marketCountryCode?.toLowerCase(),
        abo_affiliate: profileData.affiliateCode
      };
      window?.heap?.track('Platform: Account: View: ABO', accountData);

    }
    handleSearchTerm();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsDropdownOpen(true);
  }, [data])

  const filteredProfiles = data;

  return (
    <>
      {isDropdownOpen && (
        <div ref={dropdownRef} className={`user_profile_dropdown ${styles.user_profile_dropdown} ${isLoadingSearchABO ? styles.loading_overflow_hidden : ""}`}>
          <div className={`search_dropdown_title ${styles.search_dropdown_title}`}>
            {t("results")}
          </div>
          {isLoadingSearchABO ? (
            <SearchLoader num={5} />
          ) : (
            <>
              {filteredProfiles?.length > 0 ? (
                filteredProfiles?.map((profile, index) => (
                  <div
                    className={`user_profile_wrapper ${styles.user_profile_wrapper}`}
                    key={index}
                    data-abonum={profile.aboNumber}
                    onClick={() => handleProfileClick(profile)}
                  >
                    <div className={styles.user_profile}>
                      {profile.localName &&
                        <div className={`initials ${styles.initials}`}>
                          {getInitials(profile.localName || "")}
                        </div>
                      }
                    </div>
                    <div className={styles.user_profile_details}>
                      <div className={styles.user_details}>
                        <div className={`user_name ${styles.user_name}`}>
                          {getHighlightedText(profile.name, searchTerm)}
                        </div>
                        <div className={`user_info ${styles.user_info}`}>
                          <div className={`abo_num ${styles.abo_num}`}>
                            {getHighlightedText(profile.aboNumber, searchTerm)}
                          </div>
                          <div className={styles.territory}>
                            {getHighlightedText(getCountryNameFromCode(profile?.marketCountryCode, t), searchTerm)}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`profile_nav_btn ${styles.profile_nav_btn}`}
                        onClick={() => handleProfileClick(profile)}
                        data-aboNum={String(profile.aboNumber)}
                      ></div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={`no_result_text ${styles.no_result_text}`}>{t("no_results_found_for", { value: searchTerm })}</div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default UserProfileDropdown;
