import map from "lodash/map";
import forEach from "lodash/forEach";
import { UserDetails } from "../types/types";

export const getUserNativeIDsArray = (rawData: any) => {
  const nativeIDsArray = map(rawData, (dataObj) => {
    const nativeId = dataObj.audit.createdBy;
    return nativeId;
  });

  return nativeIDsArray;
};

export const getFullNameOfUserByNativeID = (nativeId: string, usersList: UserDetails[] | null) => {
  let userName = "";
  forEach(usersList, (user) => {
    if (user.nativeId === nativeId) userName = user.fullName;
  });
  return userName || nativeId;
};
