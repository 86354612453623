import React, { useState,useRef,useEffect } from "react";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import styles from "./Dropdown.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import dropdownArrowLight from "../../../assets/images/LightTheme/down_arrow_light.svg";
import dropdownArrowDark from "../../../assets/images/DarkTheme/down_arrow_dark.svg";
import useClickOutside from "../../CustomHooks/useClickOutside";

type DropdownItem = {
  code: number | string;
  value: string;
  status?: number;
  fromDate?: string;
  toDate?: string;
  localName?: string;
  accountManagerNativeId?: string;
  affiliateCode?: string;
  marketCountryCode?: string;
  marketName?: string;
};

interface DropdownProps {
  dropdownName: string;
  items: DropdownItem[] | undefined;
  selectedItem: DropdownItem | undefined;
  handleSelectItemCallback: (docType: DropdownItem) => void;
  isAPProfilePage?: boolean;
  addScroll?: boolean;
  isLoadingDropdown?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  dropdownName,
  items,
  selectedItem,
  handleSelectItemCallback,
  isAPProfilePage = false,
  addScroll = false,
  isLoadingDropdown = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const dropdownArrow =
    currentTheme === "light" ? dropdownArrowLight : dropdownArrowDark;
  let arr: number[] = new Array(5).fill(0);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectItem = (item: DropdownItem) => {
    handleSelectItemCallback(item);
    toggleDropdown();
  };
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className={`dropdown ${styles.dropdown}`}>
      <div
        className={styles.input_wrapper}
        onClick={toggleDropdown}
        data-testid="toggle_drodpwn_testId"
      >
        <input
          type="text"
          className={`dropdown_input ${styles.dropdown_input} ${selectedItem?.value ? `input_with_text ${styles.input_with_text}` : null} ${isOpen ? "selected_input" : ""}`}
          value={selectedItem?.value}
          readOnly
          data-testid="dropdown_input_testId"
          placeholder={dropdownName}
        />
        <img
          src={dropdownArrow}
          alt="Downkey"
          className={`${styles.arrow_icon} ${isOpen ? styles.rotate : ""}`}
        />
        {selectedItem?.value && (
          <label className={`placeholder ${styles.placeholder}`}>
            {dropdownName}
          </label>
        )}
      </div>

      {isOpen && (
        <div
          className={`dropdown_menu ${styles.dropdown_menu} ${isAPProfilePage ? styles.dropdown_pos : ""} ${addScroll ? styles.dropdown_height : ""} ${isLoadingDropdown ? styles.dropdown_loader : ""}`}
          data-testid="drodpwn_list_testId"
        >
          {isLoadingDropdown
            ? arr.map((_, index) => (
                <div
                  key={index}
                  className={`${styles.dropdown_item} ${styles.loader} animate`}
                ></div>
              ))
            : map(items, (item, index) => (
                <div
                  key={item?.code ?? index}
                  className={`dropdown_item ${styles.dropdown_item} ${selectedItem?.code === item?.code ? `selected ${styles.selected}` : ""}`}
                  onClick={() => handleSelectItem(item)}
                >
                  {item?.value ?? ""}
                </div>
              ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
