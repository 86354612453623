export const getTranslationKeyByCode = (code = "") => {
  switch (code) {
    case "activationRate30":
      return "activationRate";
    case "activationRate60":
      return "activationRate";
    case "activationRate90":
      return "activationRate";
      default:  return code;
  }
};