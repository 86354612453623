import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KPIsAndTilesSection from "../../ABOGrowthCompanion/KPIsAndTilesSection/KPIsAndTilesSection";
import styles from "./Pipeline.module.scss";
import SeperatorLineDark from "../../../../assets/images/DarkTheme/seperator_line.svg";
import { useGetKpiSettingsQuery } from "../../../../redux/services/kpiSettingsByTypeAPI";
import KPIsListDataMassager from "../../../CustomHooks/useGetMassagedKPIsList";
import { useAppSelector } from "../../../../redux/hooks";

const Pipeline: React.FC<CategoriesProps> = ({ category }) => {
  const { t } = useTranslation();
  const [pipelineKPIslist, setPipelineKPIslist] = useState<any>([]);
  const massagedKPIslist = KPIsListDataMassager(pipelineKPIslist ?? []);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const { data: pipelineKpisResponse } = useGetKpiSettingsQuery({
    type: "business-performance-pipeline",
    affiliateCode: userDetails.affiliateCode
  },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    const tempKPIslist = pipelineKpisResponse?.body;
    setPipelineKPIslist(tempKPIslist);
  }, [pipelineKpisResponse]);

  return (
    <div className={styles.pipeline_wrapper}>
      <div className={styles.heading_area}>
        <div className={styles.heading}>{t("pipeline")}</div>
        <div className={`section_sub_heading ${styles.sub_heading}`}>
          {t("pipeline_summary")}
        </div>
      </div>
      <KPIsAndTilesSection
        tileDataList={massagedKPIslist}
        kpiList={massagedKPIslist}
        SeperatorIcon={SeperatorLineDark}
        category={category}
      />
    </div>
  );
};

export default Pipeline;
