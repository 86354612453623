import styles from "./SearchLoader.module.scss";

type ComponentProps = {
  num: number;
};

function SearchLoader({ num }: ComponentProps) {
  let arr: number[] = new Array(num).fill(0);

  return (
    <div className={styles.search_loading_container}>
      {arr.map((_, index) => (
        <div className={`${styles.card_loader}`} key={index}>
          <div className={`${styles.profile} animate`}></div>
          <div className={`${styles.content_loader}`}>
            <div className={`${styles.first_line} animate`}></div>
            <div className={`${styles.second_line} animate`}></div>
          </div>
          <div className={`profile_nav_btn ${styles.profile_nav_btn}`}></div>
        </div>
      ))}
    </div>
  );
}

export default SearchLoader;
