import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  customVieConfigKPIList: []
};

export const customViewKpiListSlice = createSlice({
  name: "customViewConfigKPIList",
  initialState,
  reducers: {
    setCustomViewConfigKPIList: (state, action) => {
      state.customViewConfigKPIList = action.payload ;
    }
  },
});

export const { setCustomViewConfigKPIList } = customViewKpiListSlice.actions;
export default customViewKpiListSlice.reducer;
