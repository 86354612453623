export const disableEnableScroll = (scrollOff: boolean) => {
  if (typeof document !== 'undefined' && document.body) {
    if (scrollOff && document.documentElement && window && window.innerWidth) {
      const scrollBarGap = window.innerWidth - document.documentElement.clientWidth;

      if (scrollBarGap > 0) {
        document.body.style.paddingRight = `${scrollBarGap}px`;
      }
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'absolute';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.paddingRight = 'inherit';
      document.body.style.position = 'static';
      document.body.style.removeProperty('width');
    }
  }
};
