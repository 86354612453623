import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { restoreUserDetails } from '../../redux/slices/userDetailsSlice';

const useBackButtonHandler = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const handlePopState = (event: any) => {
            dispatch(restoreUserDetails());
        };
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [dispatch]);
};

export default useBackButtonHandler;