import React, { useRef, useState } from "react";
import styles from "./APInformation.module.scss";
import { useTranslation } from "react-i18next";
import APProfileSubheaderComponent from "./APProfileSubheader/APProfileSubheader";
import SidePanel from "../../common/Sidebar/Sidebar";
import APInfoDetailsComponent from "./APInfoDetails/APInfoDetails";
import Dropdown from "../../common/Dropdown/Dropdown";
import { AP_SIDE_NAV_LINKS } from "../../../constants/dataConstants";
import useScroll from "../../../redux/slices/useScrollSlice";
import { DropdownItem } from "../../../types/types";

type APInformationProps = {
  selectedAP: DropdownItem | undefined;
  listOfAPs: DropdownItem[] | undefined;
  handleSelectItemCallback: (selectedItem: DropdownItem) => void;
};

const APInformationComponent: React.FC<APInformationProps> = ({
  selectedAP,
  listOfAPs,
  handleSelectItemCallback,
}) => {
  const { t } = useTranslation();

  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("selectedAPLink") || "ap_information"
  );

  const refMap: Record<string, React.RefObject<HTMLDivElement>> = {
    background: useRef<HTMLDivElement>(null),
    documents: useRef<HTMLDivElement>(null),
    notes: useRef<HTMLDivElement>(null),
    ap_information: useRef<HTMLDivElement>(null),
  };

  const handleSectionChange = (sectionId: string) => {
    setActiveLink(sectionId);
    localStorage.setItem("selectedAPLink", sectionId);
  };

  const { handleClick } = useScroll(refMap, handleSectionChange);

  return (
    <section
      className={`ap_view_profile_container ${styles.ap_view_profile_container}`}
      data-testid="profile_testID"
    >
      <APProfileSubheaderComponent />
      <div className={styles.flex_wrapper}>
        <SidePanel
          activeLink={activeLink}
          onClickHandler={handleClick}
          //onClickHandler={handleSectionChange}
          elementRefMap={refMap}
          sideNavList={AP_SIDE_NAV_LINKS}
          isSourceAP={true}
        />
        <div
          className={styles.content_wrapper}
          data-testid="content_wrapper_testID"
        >
          <div className={`content_inner ${styles.inner_wrapper}`}>
            <div
              className={
                activeLink === "ap_information" ||
                activeLink === "background" ||
                activeLink === "documents" ||
                activeLink === "notes"
                  ? styles.active_section
                  : styles.hidden_section
              }
            >
              <div className={`${styles.ap_info_title_container}`}>
                <span className={`${styles.ap_page_title}`}>
                  {t('AP_info.ap_information')}
                </span>
                <span className={`${styles.ap_dropdown_wrapper}`}>
                  <Dropdown
                    dropdownName={t('AP_info.selectAnAP')}
                    items={listOfAPs}
                    selectedItem={selectedAP}
                    handleSelectItemCallback={handleSelectItemCallback}
                    isAPProfilePage
                    addScroll
                  />
                </span>
              </div>
              <div className={`bottom_line ${styles.bottom_line}`} />
              <APInfoDetailsComponent
                refMap={refMap}
                onSectionChange={handleSectionChange}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default APInformationComponent;
