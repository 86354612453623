import React from "react";
import ErrorHandlingComponent from "../components/ErrorHandling/ErrorHandling";
import { getURLQueryParams } from "../utils/getURLQueryParams";

function ErrorPage() {
  const queryParameters = window.location.search;
  const queryParams = getURLQueryParams(queryParameters);
  const errorCode = queryParams.get("errorType");

  return <ErrorHandlingComponent error={errorCode} />;
}

export default ErrorPage;
