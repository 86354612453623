import { HttpStatusCode } from "axios";

export const isValidResponse = (statusCode: any) => {
  switch (statusCode) {
    case HttpStatusCode.Ok:
      return true;
    case HttpStatusCode.NotFound:
      return true;
    case HttpStatusCode.NoContent:
      return true;
    default:
      return false;
  }
};
