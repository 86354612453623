import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { useAppSelector } from "../../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import {
  useGetSuccessionPlanDocsListQuery,
  useDeleteSuccessionPlanDocsItemMutation,
} from "../../../../../redux/services/successionPlanAPI";
import {
  ISuccessionPlanColumnDetails,
  ITableColumnState,
} from "../../../../../types/types";
import { sortTableData, findKeysInArray } from "../../../../../utils/common";

import DocumentTabel from "../../../../common/DocumentTable/DocumentTable";

interface ITableProp {
  documentTypeList: DropdownItem[] | undefined;
  data: any;
  isError: boolean;
  deleteDocument: (docName: string, id: string | number) => {}
}

const TableContainer: React.FC<ITableProp> = ({ documentTypeList, data, isError, deleteDocument }) => {
  const { t } = useTranslation();
  const [successionPlanList, setsuccessionPlanList] = useState<
    ISuccessionPlanColumnDetails[] | []
  >([]);

  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);

  const [columnState, setColumnState] = useState<ITableColumnState<ISuccessionPlanColumnDetails>[]>([]);

  const defaultColumnName = columnState[0]?.sortOrder === "nosort" || isEmpty(columnState) ? "updatedDate" : columnState[0]?.column;
  const defaultSort = columnState[0]?.sortOrder === "nosort" || isEmpty(columnState) ? "desc" : columnState[0]?.sortOrder;
  // const [
  //   updateSuccessionPlanDocsList,
  //   { isLoading: isLoadingDeleteSuccessioPlan },
  // ] = useDeleteSuccessionPlanDocsItemMutation();

  // Pass exact key names from response to table data 
  const tableColumnData = ["aboSuccessionId", "name", "documentType", "size", "updatedDate"];

  useEffect(() => {
    let prepareTableData = findKeysInArray(data?.body ?? [], tableColumnData);
    const codeToValueMap = new Map(documentTypeList?.map(doccode => [doccode.code, doccode.value]));
    // Add documentvalue to table data
    prepareTableData?.forEach(docRow => {
      if (codeToValueMap.has(docRow?.documentType?.toString())) {
        docRow['docTypeName'] = codeToValueMap?.get(docRow?.documentType?.toString());
        docRow['commonIdKey'] = docRow.aboSuccessionId;
      }

    });
    const intialSortData = sortTableData(prepareTableData, defaultColumnName, defaultSort);

    setsuccessionPlanList(intialSortData);

  }, [data, documentTypeList]);

  return <DocumentTabel tableData={successionPlanList} isLoading={false} columnState={columnState} setColumnState={setColumnState} deleteDocument={deleteDocument} columnsToBeVisible={["docName", "docType", "date", "actions"]} downloadDocUrlParam={"succession"} isFourColumn={true} />
};

export default TableContainer;
