import { createSlice } from "@reduxjs/toolkit";
import { ICard } from "../../types/types";

interface IContactCardState {
  contactCardDetails: ICard[];
}
// Dummy card data- Initial state
const initialState: IContactCardState = {
  contactCardDetails: [],
};

const contactCardSlice = createSlice({
  name: "contactCard",
  initialState,
  reducers: {
    updateContactCard: (state, action) => {
      state.contactCardDetails = action.payload;
    },
  },
});

export const { updateContactCard } = contactCardSlice.actions;
export default contactCardSlice.reducer;
