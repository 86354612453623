import React, { useEffect, useState } from "react";
import styles from "./ContactCardInformation.module.scss";
import isEmpty from "lodash/isEmpty";
import PersonalInfoCard from "./PersonalInfoCard/PersonalInfoCard";
import { useTranslation } from "react-i18next";
import { ICard } from "../../../../types/types";
import { useGetAboContactCardDetailsQuery } from "../../../../redux/services/aboContactCardDetailsAPI";
import { useAppSelector } from "../../../../redux/hooks";
import ContactInfoLoader from "./ContactInfoLoader/ContactInfoLoader";
import ErrorInPageScreen from "../../../common/ErrorInPageScreen/ErrorInPageScreen";
import { isValidResponse } from "../../../../utils/validateAPIResponse";

const ContactCardInformation = () => {
  const { t } = useTranslation();
  const [contactCardData, setContactCardData] = useState<ICard[] | []>([]);
  const [isViewmore, setViewMore] = useState<boolean>(false);
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [isAPIError, setIsAPIError] = useState(false);

  const { isFetching, data, isError } = useGetAboContactCardDetailsQuery(
    {
      affiliateCode: userDetails.affiliateCode,
      aboNumber: userDetails.aboNumber,
    },
    {
      skip: isAPIError,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleAPIError = () => {
    setIsAPIError(true);
    setContactCardData([]);
  };

  useEffect(() => {
    isError && handleAPIError();
  }, [isError]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (isValidResponse(data?.statusCode)) {
        setContactCardData(data?.body ?? []);
      } else {
        handleAPIError();
      }
    }
  }, [data, isFetching]);

  return (
    <>
      <div className={styles.section_title_wraper}>
        <label
          className={`content_inner_label ${styles.section_label}`}
          data-testid="contact_card_section_heading_testID"
        >
          {t("contact_information")}
        </label>
      </div>
      <div className={styles.card_holder}>
        {isFetching ? (
          <ContactInfoLoader cardNumber={4} />
        ) : isAPIError ? (
          <ErrorInPageScreen
            handleClickTryAgain={() => {
              setIsAPIError(false);
            }}
          />
        ) : (
          contactCardData?.length > 0 &&
          contactCardData
            ?.slice(0, isViewmore ? 10 : 4)
            ?.map((card, index) => (
              <PersonalInfoCard key={`${card.name || "" + index}`} {...card} />
            ))
        )}
        {contactCardData?.length > 4 && (
          <div className={styles.viewmore_Section}>
            <button
              className={`view_more_text ${styles.viewmore_label}`}
              onClick={() => setViewMore(!isViewmore)}
              data-testid="view_more_testId"
            >
              {isViewmore ? t("view_less") : t("view_more")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactCardInformation;
