import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setupInterceptors } from "./axios.interceptor";

export function InjectAxiosInterceptors() {
  const history = useLocation();

  useEffect(() => {
    setupInterceptors(history);
  }, [history]);

  // not rendering anything
  return null;
}
