import { BackgroundProps } from "../types/types";

export const getGradientBackground = (Props: BackgroundProps) => {
  const { tag } = Props;

  switch (tag?.toLowerCase()) {
    case "good":
      return {
        ABOBackground: "#F0FAE5",
        sideBar: "linear-gradient(to top, #24C072, #C3EB82, #C3EB82)",
        initials: "linear-gradient(to right, #24C072, #C3EB82)",
      };
    case "okay":
      return {
        ABOBackground: "#FFF1E8",
        sideBar: "linear-gradient(to top, #F5A066, #D3D351)",
        initials: "linear-gradient(to right, #D3D351, #F5A066)",
      };
    case "poor":
      return {
        ABOBackground: "#FFE2E2",
        sideBar: "linear-gradient(to top, #F16A81, #F5A066)",
        initials: "linear-gradient(to right, #F5A066, #F16A81)",
      };
    default:
      return {
        ABOBackground: "#F0FAE5",
        sideBar: "linear-gradient(to top, #24C072, #C3EB82, #C3EB82)",
        initials: "linear-gradient(to right, #24C072, #C3EB82)",
      };
  }
};
