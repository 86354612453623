import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { LANGUAGES } from "../../constants/dataConstants";
import styles from "./LanguageSwitcher.module.scss";
import { toggleLanguage } from "../../redux/slices/languageSlice";
import { useLocation } from 'react-router-dom';

/**
 * A language switcher component that allows users to change the language.
 *
 * @return {JSX.Element} The language switcher component
 */
const LanguageSwitcher: React.FC<I18nDropdownProps> = () => {
  const { t, i18n } = useTranslation();
  const history = useLocation();
  
  const selectedLang = useSelector(
    (state: any) => state.language.currentLanguage,
  );
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const languageNames: any = {
    en: "English",
    th: "ไทย",
  };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /**
   * Handles the language change.
   *
   * @param {string} newLng - The new language to be set.
   * @return {void} This function does not return anything.
   */
  const handleLanguageChange = (newLng: string) => {
    setIsOpen(!isOpen);
    //The language is set in the LanguageWrapper component in the dependency array which is triggered when the Language changes
    dispatch(toggleLanguage(newLng));
	const selectedLang=languageNames[newLng]

	const selectedLanguageData={
		"click_section":(history.pathname === "/profile") ? "personal_info" : "my_account",
		"click_category":"header",
		"click_action" : `click<${selectedLang}>`
		}
	
		window?.heap?.track(selectedLanguageData);
  };

  const toggleDropdown = () => {

	const languageDropdownData={
		"click_section":(history.pathname === "/profile") ? "personal_info" : "my_account",
		"click_category":"header",
		"click_action" : "dropdown<languages>"
		}
	
		window?.heap?.track(languageDropdownData);
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`dropdown_container ${styles.dropdown_container}`}
      onClick={toggleDropdown}
      ref={dropdownRef}
      data-testid="language_switcher"
    >
      <div className={styles.dropdown_heading}>
        <button className={`dropdown_button ${styles.dropdown_button}`}>
          {languageNames[i18n.language]}
        </button>
        <span
          className={`${isOpen ? "up_arrow" : "down_arrow"} ${styles.dropdown_arrow}`}
        ></span>
      </div>
      {isOpen && (
        <div className={`dropdown_menu ${styles.dropdown_menu}`}>
          {LANGUAGES.map(({ code, label }) => (
            <div
              key={code}
              className={`dropdown_item ${code === i18n.language ? "dropdown_selected_item" : ""} ${styles.dropdown_item}`}
              onClick={() => handleLanguageChange(code)}
            >
              {t(`${label}`)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
