import React, { useState } from "react";
import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import { footerLinks } from '../../../constants/dataConstants';
import { useAppSelector } from "../../../redux/hooks";

interface FooterLink {
  privacyPolicy: string;
  termsConditions: string;
}

interface FooterLinks {
  [key: string]: FooterLink;
}

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const userDetails = useAppSelector((state: any) => state.userDetails.user);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);
  const links = (footerLinks as FooterLinks)[userAffiliateCode] || {};
  
  return (
    <footer className={`footer_container ${styles.footer_container}`} data-testid="footer_component_testId">
      <div className={styles.footer_content}>
        <span className={styles.content}>{t("copyright")}</span>
        <span className={styles.links}>
          {/* TODO */}
          {/* <a href="#" target="_blank" rel="noopener noreferrer">{t("sitemap")}</a>
          <span> | </span> */}
          <a href={links.privacyPolicy} target="_blank" rel="noopener noreferrer">{t("privacy_policy")}</a>
          <span> | </span>
          <a href={links.termsConditions} target="_blank" rel="noopener noreferrer">{t("terms_conditions")}</a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
