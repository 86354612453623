import React, { ReactNode } from "react";
import Header from "./common/Header/Header";
import Footer from "./common/Footer/Footer";
import styles from "./Layout.module.scss";
import { useAppSelector } from "../redux/hooks";

interface LayoutProps {
  children: ReactNode; // Use ReactNode for more flexibility,
  isLoginPage?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, isLoginPage = false }) => {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  return (
    <div
      className={` layout_container ${styles.layout_container} ${currentTheme}`}
    >
      <Header isLoginPage={isLoginPage} />
      <main
        className={styles.main_layout_content}
        style={{ marginTop: isLoginPage ? "auto" : "" }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
