
import React, { useEffect, useState } from "react";
import {
  SortOrder,
  ITableColumnState,
} from "../../../types/types";
import isEmpty from "lodash/isEmpty";
import styles from "./DocumentTable.module.scss";
import { useAppSelector } from "../../../redux/hooks";
import downloadLight from "../../../assets/images/LightTheme/download_light.svg";
import downloadDark from "../../../assets/images/DarkTheme/dowload_dark.svg";
import deleteLight from "../../../assets/images/LightTheme/delete_light.svg";
import deleteDark from "../../../assets/images/DarkTheme/delete_dark.svg";
import arrow_up_light from "../../../assets/images/LightTheme/arrow_up_light.svg";
import arrow_up_dark from "../../../assets/images/DarkTheme/arrow_up_dark.svg";
import arrow_down_light from "../../../assets/images/LightTheme/arrow_down_light.svg";
import arrow_down_dark from "../../../assets/images/DarkTheme/arrow_down_dark.svg";
import nosort_light from "../../../assets/images/LightTheme/nosort_light.svg";
import nosort_dark from "../../../assets/images/DarkTheme/nosort_dark.svg";

import { sortTableData, translateKey } from "../../../utils/common";
import { formatDate } from "../../../utils/dateFormatter"
import { countryCodeMappings } from '../../../constants/dataConstants';
import formatFileSize from "../../../utils/formatFileSize";
import { urlConfiguration } from "../../../config/urlConfiguration";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../config/interceptors/axios.interceptor";



interface TableProps<T> {
  tableData: T[]; // Define columns as an array of keys of T
  isLoading: boolean,
  columnState: ITableColumnState<T>[],
  setColumnState: (arg: ITableColumnState<T>[]) => void,
  deleteDocument: (name: string, id: string | number) => {},
  columnsToBeVisible: string[],
  rowLimit?: number,
  downloadDocUrlParam?: string,
  isFourColumn?: boolean,
  screenName?: string,
  sortNotApplicable?: boolean,
}

// Default column name which is a key of ITableColumnDetails

const DocumentTabel = <T,>({ tableData, isLoading, columnState, setColumnState, deleteDocument, columnsToBeVisible, rowLimit, isFourColumn, downloadDocUrlParam = '', screenName, sortNotApplicable }: TableProps<T>) => {
  const { t } = useTranslation();

  const [tableDataList, setTableDataList] = useState<any[]>(tableData);

  useEffect(() => {
    setTableDataList(tableData);
  }, [tableData]);

  const userDetails = useAppSelector((state: any) => state.userDetails.user);

  const [affiliateCode] = useState(userDetails?.affiliateCode || 0);
  const [aboNumber] = useState(userDetails?.aboNumber || 0);
  const [userAffiliateCode] = useState(userDetails?.userAffiliateCode || 0);

  const [isViewmore, setViewMore] = useState<boolean>(false);
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const selectedSortackgroundColor = currentTheme === "light" ? "#CACACA" : "#494949";
  const downloadIcon = currentTheme === "light" ? downloadLight : downloadDark;
  const deleteIcon = currentTheme === "light" ? deleteLight : deleteDark;
  const selectedAP = useAppSelector((state: any) => state.apDetails.selectedAP);

  function partitionFileName(fileName: string) {
    const parts = fileName.split('.');
    return parts;
  }

  const downloadDocument = async (title: string, id: string | number, urlparam: string, isViewDoc: boolean) => {
    const apCode = selectedAP?.code || '';
    const URL = screenName == "apInformation" ? `${urlConfiguration.baseUrl}/am/ap/${apCode}/${urlparam}/${id}` : `${urlConfiguration.baseUrl}/am/abo/${userDetails.affiliateCode}/${userDetails.aboNumber}/${urlparam}/${id}`
    try {
      const response = await axiosInstance.get(URL,
        {
          responseType: "blob",
        }
      );

      const dataBlob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(dataBlob);
      if (isViewDoc && partitionFileName(title)[1] === 'pdf') {
        window.open(url, "_blank");
        return;
      }
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute(
        "download",
        `${title}`
      );
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading Docs:", error);
    }
  };

  const OnclickApplySort = <T,>(column: keyof T) => {
    let newState: SortOrder | null = "asc";

    // Find the current sorting state of the column
    const columnIndex = columnState?.findIndex(
      (state: any) => state.column === column,
    );
    if (columnIndex !== -1) {
      const currentState = columnState[columnIndex].sortOrder;
      if (currentState === "asc") {
        newState = "desc";
      } else if (currentState === "desc") {
        newState = 'nosort';
      }
    }

    // Update the sorting state for the column
    const newColumnState: any = [...columnState];

    newColumnState.splice(columnIndex, 1); // single sort
    newColumnState.push({ column, sortOrder: newState }); // single sort

    // Multi sort 
    // if (columnIndex !== -1 && newState === null) {
    //   newColumnState.splice(columnIndex, 1);
    // } else if (columnIndex !== -1) {
    //   newColumnState[columnIndex].sortOrder = newState;
    // } else {
    //   newColumnState.push({ column, sortOrder: newState });
    // }
    setColumnState(newColumnState);

    const defaultColumnName: any = newColumnState[0].sortOrder === "nosort" ? "updatedDate" : newColumnState[0].column;
    const defaultSort: any = newColumnState[0].sortOrder === "nosort" ? "desc" : newColumnState[0].sortOrder;

    const sortedData = sortTableData(tableData, defaultColumnName, defaultSort);
    setTableDataList(sortedData);
  };

  const addTableSortIcon = (colName: string) => {
    let headerCellIcon = '';
    const isValidForTheCell = columnState?.length > 0 && columnState[0]?.column === colName && columnState[0]?.sortOrder;
    if (currentTheme === "light") {
      headerCellIcon = isValidForTheCell == "asc" ? arrow_up_light : isValidForTheCell === "desc" ? arrow_down_light : nosort_light;
    } else {
      headerCellIcon = isValidForTheCell == "asc" ? arrow_up_dark : isValidForTheCell === "desc" ? arrow_down_dark : nosort_dark;
    }
    return headerCellIcon;
  }

  return isLoading ? (
    <div className={styles.empty_state_document}>
      <div className={`table_no_data ${styles.empty_state_msg}`}>
        {t("loading")}
      </div>
    </div>
  ) : (
    isEmpty(tableDataList) ?
      <div className={styles.empty_state_document}>
        <div className={`table_no_data ${styles.empty_state_msg}`}>
          {t(translateKey("noDocumentYet", screenName))}
        </div>
      </div>
      :
      <section
        className={`table_container ${styles.table_container}`}
        data-testid="table_section_testID"
      >
        <div className={`${styles.table_wrapper} ${isFourColumn ? styles.succession_table : ""}`}>
          <table className={styles.table}>
            <thead className={styles.tbl_header_wrapper}>
              <tr className={`tbl_header ${styles.tbl_header}`}>
                {columnsToBeVisible.includes("docName") && <th
                  className={`header_cell ${styles.header_cell}`}
                  data-testid="table_column_header_testID"
                  style={{ backgroundColor: columnState?.length > 0 && columnState[0]?.column === "name" && columnState[0]?.sortOrder !== "nosort" ? selectedSortackgroundColor : "" }}
                >
                  {t(translateKey("title", screenName))}
                  <img
                    src={addTableSortIcon('name')}
                    alt="sort"
                    style={{ display: columnState?.length > 0 && columnState[0]?.column === "name" && columnState[0]?.sortOrder !== "nosort" ? "flex" : "" }}
                    onClick={() => sortNotApplicable ? "" : OnclickApplySort("name")}
                    id="title"
                  />
                </th>}
                {columnsToBeVisible.includes("docType") && <th className={`header_cell ${styles.header_cell}`}
                  style={{ backgroundColor: columnState?.length > 0 && columnState[0]?.column === "docTypeName" && columnState[0]?.sortOrder !== "nosort" ? selectedSortackgroundColor : "" }}
                >
                  {t(translateKey("type", screenName))}
                  <img
                    src={addTableSortIcon('docTypeName')}
                    style={{ display: columnState?.length > 0 && columnState[0]?.column === "docTypeName" && columnState[0]?.sortOrder !== "nosort" ? "flex" : "" }}
                    alt="sort"
                    onClick={() => sortNotApplicable ? "" : OnclickApplySort("docTypeName")}
                    id="type"
                  />
                </th>}
                {columnsToBeVisible.includes("date") && <th className={`header_cell ${styles.header_cell}`}
                  style={{ backgroundColor: columnState?.length > 0 && columnState[0]?.column === "updatedDate" && columnState[0]?.sortOrder !== "nosort" ? selectedSortackgroundColor : "" }}
                >
                  <span>{t(translateKey(screenName === "apInformation" ? "date" : "modified", screenName))}</span>
                  <img
                    src={addTableSortIcon('updatedDate')}
                    alt="sort"
                    style={{ display: columnState?.length > 0 && columnState[0]?.column === "updatedDate" && columnState[0]?.sortOrder !== "nosort" ? "flex" : "" }}
                    onClick={() => sortNotApplicable ? "" : OnclickApplySort("updatedDate")}
                    id="date"
                  />
                </th>}
                {columnsToBeVisible?.includes("actions") && <th className={`header_cell ${styles.header_cell}`}>
                  {t(translateKey("actions", screenName))}
                </th>}
              </tr>
            </thead>
            <tbody>

              {tableDataList && tableDataList?.slice(0, rowLimit ? isViewmore ? 15 : rowLimit : Number.MAX_SAFE_INTEGER)
                ?.map(
                  (doc: any, index: number) => (
                    <tr
                      key={`${index}${doc.commonIdKey}`}
                      className={`tbl_data_row ${styles.tbl_data_row}`}
                      data-testid="table_row_testID"
                    >
                      {columnsToBeVisible.includes("docName") && <td className={`data_cell ${styles.data_cell}`}>
                        <span
                          className={styles.file_name}
                          onClick={() => downloadDocument(doc.name, doc.commonIdKey, downloadDocUrlParam, true)}
                        >
                          {/* accepting dot in file name only */}
                          {partitionFileName(doc.name).slice(0, partitionFileName(doc.name)?.length - 1).join('.')}
                        </span>
                        <span
                          className={styles.file_size}
                        >{`${formatFileSize(doc.size)}`}</span>
                      </td>}
                      {columnsToBeVisible.includes("docType") && <td className={`data_cell ${styles.data_cell}`}>
                        <span
                          className={styles.type_name}
                        >{`${doc.docTypeName}`}</span></td>}
                      {columnsToBeVisible.includes("date") && <td className={`data_cell ${styles.data_cell}`}>
                        {formatDate(doc.updatedDate, countryCodeMappings[userAffiliateCode], 'dateTime')}
                      </td>}
                      {columnsToBeVisible.includes("actions") && <td className={`data_cell ${styles.data_cell}`}>
                        <div
                          className={`download_icon ${styles.download_icon}`}
                          onClick={() => downloadDocument(doc.name, doc.commonIdKey, downloadDocUrlParam, false)}
                        >
                          <img src={downloadIcon} alt="download" />
                        </div>
                        <div
                          className={styles.delete_icon}
                          onClick={() =>
                            deleteDocument(doc.name, doc.commonIdKey)
                          }
                        >
                          <img src={deleteIcon} alt="delete" />
                        </div>
                      </td>}
                    </tr>
                  ),
                )}
              {rowLimit && tableDataList?.length > rowLimit && (
                <div className={styles.viewmore_Section}>
                  <button
                    className={`view_more_text ${styles.viewmore_label}`}
                    onClick={() => setViewMore(!isViewmore)}
                    data-testid="view_more_testId"
                  >
                    {/* {isViewmore ? t("view_less") : t("view_more")} */}
                    {isViewmore ? t(translateKey("view_less", screenName)) : t(translateKey("view_more", screenName))}
                  </button>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </section>
  );
};

export default DocumentTabel;