import React from "react";
import { BrowserRouter, useRoutes, Navigate, Outlet } from "react-router-dom";
import LanguageWrapper from "../components/LanguageWrapper/LanguageWrapper";
import Layout from "../components/Layout";
import ProfilePage from "../pages/profile";
import MyAccountPage from "../pages/myaccount";
import LoginPage from "../pages/login";
import APPage from "../pages/ap";
import { InjectAxiosInterceptors } from "../config/interceptors";
import TokenHandlerPage from "../pages/tokenHandler";
import ErrorPage from "../pages/error";
import { loadStateFromSessionStorage } from "../utils/sessionStorageUtility";
import config from "../config/config";
import { useAppSelector } from "../redux/hooks";

/**
 * This is a function that defines the AppRoute component.
 *
 * @return {ReactNode} The routes for the AppRoute component.
 */
const AppRoute = () => {
  const storedUserDetails = loadStateFromSessionStorage();
  const userDetails: any = useAppSelector(
    (state: any) => state.userDetails.user
  );

  let routes = useRoutes([
    {
      path: "/",
      element: <Navigate to={`/login`} replace />,
    },
    { path: "/token-handler", element: <TokenHandlerPage /> },
    {
      path: "/error",
      element: (
        <LanguageWrapper>
          <ErrorPage />
        </LanguageWrapper>
      ),
    },
    {
      path: "/login",
      element: (
        <Layout isLoginPage>
          <LanguageWrapper>
            <LoginPage />
          </LanguageWrapper>
        </Layout>
      ),
    },
    {
      path: "/",
      element: (
        <Layout>
          <LanguageWrapper>
            <Outlet />
          </LanguageWrapper>
        </Layout>
      ),
      children: [
        { path: "myaccount", element: <MyAccountPage /> },
        {
          path: "profile",
          element:
            storedUserDetails &&
            storedUserDetails.affiliateCode &&
            storedUserDetails.aboNumber ? (
              <ProfilePage />
            ) : (
              <Navigate to={`/myaccount`} replace />
            ),
        },
        {
          path: "ap",
          element:
            config?.isAPFeatureEnabled && userDetails?.isAccessibleAPFeature ? (
              <APPage />
            ) : (
              <Navigate to={`/myaccount`} replace />
            ),
        },
      ],
    },
    // All path that are not defined will be redirected to the my accounts page
    {
      path: "*",
      element: <Navigate to={`/myaccount`} replace />,
    },
  ]);

  return routes;
};

function Router() {
  return (
    <BrowserRouter>
      <InjectAxiosInterceptors />
      <AppRoute />
    </BrowserRouter>
  );
}

export default Router;
