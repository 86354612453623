import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import styles from "./AP.module.scss";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import NoAPSelectedLight from "../../assets/images/LightTheme/coming_soon_light.svg";
import NoAPSelectedDark from "../../assets/images/DarkTheme/coming_soon_dark.svg";
import Dropdown from "../common/Dropdown/Dropdown";
import APInformationComponent from "./APInformation/APInformation";
import { useGetAPDropdownListQuery } from "../../redux/services/apDropdownAPI";
import { DropdownItem } from "../../types/types";
import { setAPDetails } from "../../redux/slices/apslice";
import ErrorHandlingComponent from "../ErrorHandling/ErrorHandling";

interface APDropdown {
  code: string;
  name: string;
  localName: string;
  accountManagerNativeId: string;
  affiliateCode: string;
  marketCountryCode: string;
  marketName: string;
}

const APComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const noAPSelectedImage =
    currentTheme === "light" ? NoAPSelectedLight : NoAPSelectedDark;

  const [apDropdownList, setAPDropdownList] = useState<DropdownItem[] | undefined>(undefined);
  const [isAPIError, setIsAPIError] = useState(false);

  const { data, isFetching, isError } = useGetAPDropdownListQuery();

  const handleAPIError = () => {
    setIsAPIError(true);
    setAPDropdownList([]);
  }; 

  useEffect(() => {
    isError && handleAPIError();
  }, [isError])

  const apDropdownDataMassager = (rawData: APDropdown[] = []) => {
    const massagedData = map(rawData, (dataItem) => {
      let newDataItem: DropdownItem = {
        code: dataItem.code ?? "",
        value: dataItem.name ?? "",
        localName: dataItem.localName ?? "",
        accountManagerNativeId: dataItem.accountManagerNativeId ?? "",
        affiliateCode: dataItem.affiliateCode ?? "",
        marketCountryCode: dataItem.marketCountryCode ?? "",
        marketName: dataItem.marketName ?? "",
      };

      return newDataItem;
    });
    return massagedData;
  };

  useEffect(() => {
    setAPDropdownList(apDropdownDataMassager(data?.body));
  }, [data]);

  const [selectedAP, setSelectedAP] = useState<DropdownItem | undefined>(
    undefined
  );

  const handleSelectItem = (selectedItem: DropdownItem) => {
    setSelectedAP(selectedItem);
    dispatch(setAPDetails(selectedItem));
  };

  return (
    <div className={`ap_main_container ${styles.ap_main_container}`}>
      {isAPIError ? (
        <ErrorHandlingComponent error={"genericError"} />
      ) : isEmpty(selectedAP) ? (
        <div className={`${styles.ap_empty_state_container}`}>
          <div className={`${styles.ap_dropdown_wrapper}`}>
            <div className={`${styles.ap_dropdown_inner_wrapper}`}>
              <div className={`${styles.ap_dropdown}`}>
                <Dropdown
                  dropdownName={t("AP_info.selectAnAP")}
                  items={apDropdownList}
                  selectedItem={selectedAP}
                  handleSelectItemCallback={handleSelectItem}
                  addScroll
                  isLoadingDropdown={isFetching}
                />
              </div>
            </div>
          </div>
          {/* <div className={`${styles.no_ap_selected_wrapper}`}>
            <img src={noAPSelectedImage} alt={t("No_AP_Selected")} />
          </div> */}
          <div
            className={`ap_empty_state_container ${styles.no_ap_selected_wrapper}`}
          >
            <img
              src={noAPSelectedImage}
              alt={t("No_AP_Selected")}
              className={styles.no_ap_selected_image}
            />
            <p className={`no_ap_selected_text ${styles.no_ap_selected_text}`}>
              {t("AP_info.Select an AP to get started")}
            </p>
          </div>
        </div>
      ) : (
        <APInformationComponent
          selectedAP={selectedAP}
          listOfAPs={apDropdownList}
          handleSelectItemCallback={handleSelectItem}
        />
      )}
    </div>
  );
};

export default APComponent;
