// localStorageUtils.js
export const saveStateToSessionStorage = (userDetails:any) => {
    try {
      const serializedState = JSON.stringify(userDetails);
      sessionStorage.setItem('U_D', serializedState);
    } catch (e) {
      console.error('Could not save state', e);
    }
  };
  
  export const loadStateFromSessionStorage = () => {
    try {
      const serializedState = sessionStorage.getItem('U_D');
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      console.error('Could not load state', e);
      return undefined;
    }
  };
  