import React from "react";
import styles from "./SavedViewCard.module.scss";
import DeleteIconDark from "../../../assets/images/DarkTheme/delete_bin_icon.svg";
import EditIconDark from "../../../assets/images/DarkTheme/edit_icon.svg";
import DeleteIconLight from "../../../assets/images/LightTheme/delete_bin_icon.svg";
import EditIconLight from "../../../assets/images/LightTheme/edit_icon.svg";
import { useAppSelector } from "../../../redux/hooks";
import { useTranslation } from "react-i18next";

interface SaveCardProps {
  title: string;
  isDefault: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const SavedViewCard: React.FC<SaveCardProps> = ({
  title,
  isDefault,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  const EditIcon = currentTheme === "dark" ? EditIconDark : EditIconLight;
  const DeleteIcon = currentTheme === "dark" ? DeleteIconDark : DeleteIconLight;

  return (
    <div className={`saved_view_card ${styles.saved_view_card}`}>
      <div className={`title ${styles.title}`}>{title}</div>
      <div className={styles.icon_wrapper}>
        {isDefault && <span className={`tag ${styles.tag}`}>{t("default")}</span>}
        <div className={styles.edit_icon} onClick={onEdit}>
          <img src={EditIcon} alt="Edit" />
        </div>
        <div className={styles.delete_icon} onClick={onDelete}>
          <img src={DeleteIcon} alt="Delete" />
        </div>
      </div>
    </div>
  );
};

export default SavedViewCard;
