import React from "react";
import styles from "./Sidebar.module.scss";
import { useTranslation } from "react-i18next";
import map from "lodash/map";
import { SideNavInnerTabType, SideNavType } from "../../../types/types";
import { isDisabled } from "@testing-library/user-event/dist/utils";

interface SidePanelProps {
  onClickHandler: (
    tabID: string,
    elementRefMap: Record<string, React.RefObject<HTMLDivElement>>
  ) => void;
  activeLink: string;
  elementRefMap: Record<string, React.RefObject<HTMLDivElement>>;
  sideNavList: SideNavType[];
  isSourceAP: boolean;
}
const findFirstEnabledInnerTabId = (arr: SideNavInnerTabType[]): string => {
  for (let index = 0; index < arr?.length; index++) {
    if (!arr[index].isDisabled) {
      return arr[index].tabId;
    }
  }
  return arr[0].tabId;
}

 


const SidePanel = ({
  onClickHandler,
  activeLink,
  elementRefMap,
  sideNavList,
  isSourceAP,
}: SidePanelProps) => {
  const { t } = useTranslation();

  return (
    <aside className={`sidebar_container ${styles.sidebar_container}`}>
      {map(sideNavList, (topLinkItem, index) => (
        <div
          className={styles.section}
          key={`${topLinkItem?.mainTabID}+${index}`}
        >
          <div
            className={`section_title ${styles.section_title}`}
            onClick={() =>
              onClickHandler(findFirstEnabledInnerTabId(topLinkItem?.innerTabs), elementRefMap)
            }
          >
            {/* {t(topLinkItem?.mainTabID)} */}
            {t(
              isSourceAP
                ? `AP_info.${topLinkItem?.mainTabID}`
                : topLinkItem?.mainTabID
            )}
          </div>
          <ul className={styles.items}>
            {map(topLinkItem?.innerTabs, (innerTabLink, index) => (
              <li
                className={`link_item ${innerTabLink?.isDisabled ? `disabled_tab ${styles.disabled_tab}` : ""} ${styles.link_item} ${activeLink === innerTabLink?.tabId ? `selected_link ${styles.selected_link}` : ""}`}
                onClick={() => {
                  onClickHandler(innerTabLink?.tabId, elementRefMap);
                }}
                key={index}
                data-testid={innerTabLink?.tabId}
              >
                {/* {t(innerTabLink?.tabId)} */}
                <div className={`${styles.link_title}`}>
                  {t(
                    isSourceAP
                      ? `AP_info.${innerTabLink?.tabId}`
                      : innerTabLink?.tabId
                  )}
                </div>
                {innerTabLink?.isDisabled && (
                  <div className={`${styles.coming_soon}`}>{t('coming_soon')}</div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </aside>
  );
};

export default SidePanel;
