import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Supported_Lang } from "./constants/dataConstants";

const i18nInstance = i18n.createInstance();
i18nInstance
  // load translation using http -> see /public/locales/{language-code}/{namespace}.json (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // Hence we dont need to use resources in this case and only load the selected locale instead of loading all the lang files initially
  .use(Backend)
  .use(LanguageDetector)
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    // Config options

    // To avoid sending useless requests to our
    // server, we specify the supported languages
    supportedLngs: Supported_Lang,

    // Specifies the default language (locale) used
    // when a user visits our site for the first time.
    // We use English here, but other locale can also be used.
    // lng: "en",

    // Fallback locale used when a translation is
    // missing in the active locale. Again, use your
    // preferred locale here.
    fallbackLng: "en",

    // Enables useful output in the browser’s
    // dev console.
    debug: true,

    // Normally, we want `escapeValue: true` as it
    // ensures that i18next escapes any code in
    // translation messages, safeguarding against
    // XSS (cross-site scripting) attacks. However,
    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
    },
  });

export default i18nInstance;
