import React, { useLayoutEffect } from "react";
import Router from "../src/utils/router";
import "./i18n";
import "./App.scss";
import { useAppSelector } from "./redux/hooks";
import useBackButtonHandler from "../src/components/CustomHooks/useBackButtonHandler";
import useConnectivity from "./components/CustomHooks/useConnectivity";

function App() {
  const currentTheme = useAppSelector((state: any) => state.theme.currentTheme);
  useBackButtonHandler();
  const { isOnline } = useConnectivity();

  useLayoutEffect(() => {
    if (!isOnline) {
      window.location.href = `/error?errorType=${"connectivityIssue"}`;
    }
  }, [isOnline])

  return (
    <div className={`theme_provider ${currentTheme}`}>
      <Router />
    </div>
  );
}

export default App;
